import axios from 'axios';

// export const url = 'https://dicipgostg.argesware.com';

export const url = 'https://backoffice.dicipgo.com/';

// export const url = 'http://localhost:4000/';

const instancePro = axios.create({
  baseURL: url,
  headers: {
    'Content-Type': 'application/json',
  },
});

export default instancePro;
