import axios from '../config/Axios';
export const url = '/api/company';

export const createCompany = ({
  token,
  rtn,
  name,
  representativename,
  email,
  representativephone,
  direction,
  contractstartdate,
  status
}) => new Promise((resolve, reject) => {
  if (
    rtn &&
    name &&
    representativename &&
    email &&
    representativephone &&
    direction &&
    contractstartdate &&
    status
  ) {
    axios.post(`${url}/create/basic/`, {
      token,
      rtn,
      name,
      representativename,
      email,
      representativephone,
      direction,
      contractstartdate,
      status,
    }, {
      headers: {
        AuthorizationApp: token,
      },
    }).then((res) => {
      const { data } = res;
      if (data.status === 'success') {
        resolve(data);
      } else {
        reject(data);
      }
    }).catch((error) => reject(error));
  } else if (!rtn) {
    reject({
      status: 'error',
      info: 'RTN no definido',
    });
  } else if (!name) {
    reject({
      status: 'error',
      info: 'Nombre no definido',
    });
  } else if (!representativename) {
    reject({
      status: 'error',
      info: 'Nombre del Representante no definido',
    });
  } else if (!direction) {
    reject({
      status: 'error',
      info: 'Dirección no definida',
    });
  } else if (!contractstartdate) {
    reject({
      status: 'error',
      info: 'Fecha de inicio del contrato no definido',
    });
  } else if (!status) {
    reject({
      status: 'error',
      info: 'Estado no definido',
    });
  } else {
    reject({
      status: 'error',
      info: 'Error al guardar el dato',
    });
  }
});

export const updateCompany = ({
  token,
  _id,
  rtn,
  name,
  representativename,
  email,
  representativephone,
  direction,
  contractstartdate,
  status,
}) => new Promise((resolve, reject) => {
  if (
    rtn &&
    name &&
    representativename &&
    email &&
    representativephone &&
    direction &&
    contractstartdate &&
    status
  ) {
    axios.put(`${url}/update/basic/${_id}`, {
      rtn,
      name,
      representativename,
      email,
      representativephone,
      direction,
      contractstartdate,
      status,
    }, {
      headers: {
        AuthorizationApp: token,
      },
    }).then((res) => {
      const { data } = res;
      if (data.status === 'success') {
        resolve(data);
      } else {
        reject(data);
      }
    }).catch((error) => reject(error));
  } else if (!rtn) {
    reject({
      status: 'error',
      info: 'RTN no definido',
    });
  } else if (!name) {
    reject({
      status: 'error',
      info: 'Nombre no definido',
    });
  } else if (!representativename) {
    reject({
      status: 'error',
      info: 'Nombre del Representante no definido',
    });
  } else if (!direction) {
    reject({
      status: 'error',
      info: 'Dirección no definida',
    });
  } else if (!contractstartdate) {
    reject({
      status: 'error',
      info: 'Fecha de inicio del contrato no definido',
    });
  } else if (!status) {
    reject({
      status: 'error',
      info: 'Estado no definido',
    });
  } else {
    reject({
      status: 'error',
      info: 'Error al guardar el dato',
    });
  }
});

export const listCompanies = ({ token,  page, count }) => new Promise((resolve, reject) => {
  if (token) {
    axios.get(`${url}/list/basic/`, {
      headers: {
        AuthorizationApp: token,
      },
      params: {
        page,
        count,
      },
    }).then((res) => {
      const { data } = res;
      if (data.status === 'success') {
        resolve(data);
      } else {
        reject(data);
      }
    }).catch((error) => reject(error));
  } else {
    reject({
      status: 'error',
      info: 'Token no definido',
    });
  }
});

export const totalMetricsCompany = ({token}) => new Promise((resolve, reject) => {
  if (token) {
    axios.get(`${url}/metrics/total`, {
      headers: {
        AuthorizationApp: token,
      }
    }).then((res) => {
      if (res.data.status === 'success') {
        resolve(res.data);
      } else {
        reject(res.data);
      }
    }).catch((error) => {
      reject(error?.response?.data);
    })
  } else {
    reject({
      status: 'error',
      info: 'Token no definido'
    });
  }
});

export const getOneCompany = ({
  token, _id,
}) => new Promise((resolve, reject) => {
  if (token && _id) {
    axios.get(`${url}/one/basic/${_id}`, {
      headers: {
        AuthorizationApp: token
      }
    }).then((res) => {
      if (res.data.status === 'success') {
        resolve(res.data);
      } else {
        reject(res.data);
      }
    }).catch((error) => {
      reject(error?.response?.data);
    });
  } else if (!token) {
    reject({
      status: 'error',
      info: 'Token no definido'
    });
  } else {
    reject({
      status: 'error',
      info: 'Identificador no definido'
    });
  }
});

export const listCompany = ({
  token
}) => new Promise((resolve, reject) => {
  if (token) {
    axios.get(`${url}/list/all`, {
      headers: {
        AuthorizationApp: token
      }
    }).then((res) => {
      if (res.data.status === 'success') {
        resolve(res.data);
      } else {
        reject(res.data);
      }
    }).catch((error) => {
      reject(error?.response?.data);
    });
  } else {
    reject({
      status: 'error',
      info: 'Token no definido'
    });
  }
})
