import axios from '../config/Axios';

const url = '/api/refer';

export const createRefer = ({
  token,
  referredUserId,
  refererId,
  duration,
  referCode,
  bonus,
}) => new Promise((resolve, reject) => {
  if (token && referredUserId && refererId && duration && referCode && bonus) {
    axios.post(`${url}/create/basic/`, {
      referredUserId,
      refererId,
      duration,
      referCode,
      bonus,
    }, {
      headers: {
        AuthorizationApp: token,
      },
    }).then((res) => {
      if (res.data.status === 'success') {
        resolve(res.data);
      } else {
        reject(res.data);
      }
    }).catch((error) => {
      reject(error.response?.data);
    })
  } else if (!token) {
    return reject({
      status: 'error',
      info: 'Token no definido'
    });
  } else if (!referredUserId) {
    return reject({
      status: 'error',
      info: 'Usuario Referido no definido'
    });
  } else if (!refererId) {
    return reject({
      status: 'error',
      info: 'Usuario Referente no definido'
    });
  } else if (!referCode) {
    return reject({
      status: 'error',
      info: 'Codigo de Referido no definido'
    });
  } else if (!bonus && bonus !== 0) {
    return reject({
      status: 'error',
      info: 'Bono de Referido no definido'
    });
  } else {
    return reject({
      status: 'error',
      info: 'Duracion de referidos no definido'
    });
  }
})

export const updateRefer = ({
  token,
  referredUserId,
  refererId,
  duration,
  _id,
  bonus,
}) => new Promise((resolve, reject) => {
  if (token && referredUserId && refererId && duration && _id && bonus) {
    axios.put(`${url}/update/basic/${_id}`, {
      referredUserId,
      refererId,
      duration,
      bonus,
    }, {
      headers: {
        AuthorizationApp: token,
      },
    }).then((res) => {
      if (res.data.status === 'success') {
        resolve(res.data);
      } else {
        reject(res.data);
      }
    }).catch((error) => {
      reject(error.response?.data);
    })
  } else if (!token) {
    return reject({
      status: 'error',
      info: 'Token no definido'
    });
  } else if (!referredUserId) {
    return reject({
      status: 'error',
      info: 'Usuario Referido no definido'
    });
  } else if (!refererId) {
    return reject({
      status: 'error',
      info: 'Usuario Referente no definido'
    });
  } else if (!bonus && bonus !== 0) {
    return reject({
      status: 'error',
      info: 'Usuario Referente no definido'
    });
  } else {
    return reject({
      status: 'error',
      info: 'Identificador de referidos no definido'
    });
  }
});

export const listRefer = ({ token, page, count }) => new Promise((resolve, reject) => {
  if (token) {
    axios.get(`${url}/list/basic/`, {
      headers: {
        AuthorizationApp: token,
      },
      params: {
        page,
        count,
      },
    }).then((res) => {
      const { data } = res;
      if (data.status === 'success') {
        resolve(data);
      } else {
        reject(data);
      }
    }).catch((error) => reject(error));
  } else {
    reject({
      status: 'error',
      info: 'Token no definido',
    });
  }
});

export const deleteRefer = ({token, _id}) => new Promise((resolve, reject) => {
  if (token && _id) {
    axios.delete(`${url}/list/basic/`, {
      headers: {
        AuthorizationApp: token,
      },
    }).then((res) => {
      const { data } = res;
      if (data.status === 'success') {
        resolve(data);
      } else {
        reject(data);
      }
    }).catch((error) => reject(error));
  } else if (!_id) {
    reject({
      status: 'error',
      info: 'Identificador no definido',
    });
  } else {
    reject({
      status: 'error',
      info: 'Token no definido',
    });
  }
})