/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Box,
  Typography,
  Input,
  TextField,
  MenuItem,
  Autocomplete,
} from "@mui/material";
import moment from 'moment';
import './Users.css';
import { useState } from 'react';
import { createUser, listUsers, updateUser } from '../../../api/user.api';
import { connect } from 'react-redux';
import toastr from 'toastr';
import { url } from '../../../config/Axios';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import 'moment/locale/es-mx';

const AddOrEditModal = ({
  showAddModal,
  setShowAddModal,
  handleSubmit,
  isEdit,
  elementEdit,
  setSelectedStatus,
  stateData,
  statusInfo,
  selectedStatus,
}) => (
  <Dialog open={showAddModal} onClose={() => setShowAddModal(false)} fullWidth maxWidth="lg">
    <DialogTitle>{isEdit ? 'Editar' : 'Nuevo'} Usuario</DialogTitle>
    <DialogContent>
      <form id="element-form" onSubmit={(e) => handleSubmit(e)}>
        <div className="form-main-container">
          <div className="form-input-container">
            <Box mb={1} ml={0.5} width={"100%"}>
              <Typography component="label" variant="subtitle1" fontWeight="bold">
                Nombre Completo
              </Typography>
            </Box>
            <Input className="full-width" id="loftyName" name="loftyName" placeholder="Nombre Completo" defaultValue={elementEdit.loftyName}
              inputProps={{
                maxLength: 500,
                step: "1"
              }}
            />
          </div>
          <div className="form-input-container">
            <Box mb={1} ml={0.5} width={"100%"}>
              <Typography component="label" variant="subtitle1" fontWeight="bold">
                Email
              </Typography>
            </Box>
            <Input className="full-width" id="email" name="email" placeholder="Email" defaultValue={elementEdit.loftyEmail}
              type="email"
              inputProps={{
                maxLength: 500,
                step: "1"
              }}
            />
          </div>
          <div className="form-input-container">
            <Box mb={1} ml={0.5} width={"100%"}>
              <Typography component="label" variant="subtitle1" fontWeight="bold">
                Teléfono
              </Typography>
            </Box>
            <Input className="full-width" id="phone" name="phone" placeholder="Teléfono" defaultValue={elementEdit.phone}
              inputProps={{
                maxLength: 50,
                step: "1"
              }}
            />
          </div>
          <div className="form-input-container">
            <Box mb={1} ml={0.5} width={"100%"}>
              <Typography component="label" variant="subtitle1" fontWeight="bold">
                DNI
              </Typography>
            </Box>
            <Input className="full-width" id="dni" name="dni" placeholder="0801123443212" defaultValue={elementEdit.dni}
              inputProps={{
                maxLength: 20,
                step: "1"
              }}
            />
          </div>
          <div className="form-input-container">
            <Box mb={1} ml={0.5}>
              <Typography component="label" variant="subtitle1" fontWeight="bold">
                Imagen DNI
              </Typography>
              {isEdit && (
                <a
                  href={`${url}api/user/lofty/file/lofty/local/${elementEdit.dniImage}`}
                  className="btn btn-transparent btn-sm"
                  target="_blank"
                >
                  <CloudDownloadIcon />
                </a>
              )}
            </Box>
            <Input id="dniImage" name="dniImage" placeholder="Imagen DNI" type="file" />
          </div>
          <div className="form-input-container">
            <Box mb={1} ml={0.5}>
              <Typography component="label" variant="subtitle1" fontWeight="bold">
                Licencia de Conducción
              </Typography>
              {isEdit && (
                <a
                  href={`${url}api/user/lofty/file/lofty/local/${elementEdit.driversLicense}`}
                  className="btn btn-transparent btn-sm"
                  target="_blank"
                >
                  <CloudDownloadIcon />
                </a>
              )}
            </Box>
            <Input id="driversLicense" name="driversLicense" placeholder="Licencia de Conducción" type="file" />
          </div>
          <div className="form-input-container">
            <Box mb={1} ml={0.5}>
              <Typography component="label" variant="subtitle1" fontWeight="bold">
                Captura de Perfil de Aplicación
              </Typography>
              {isEdit && (
                <a
                  href={`${url}api/user/lofty/file/lofty/local/${elementEdit.appProfile}`}
                  className="btn btn-transparent btn-sm"
                  target="_blank"
                >
                  <CloudDownloadIcon />
                </a>
              )}
            </Box>
            <Input id="appProfile" name="appProfile" placeholder="Uber, InDrive, etc..." type="file" />
          </div>
          <div className="form-input-container">
            <Box mb={1} ml={0.5}>
              <Typography component="label" variant="subtitle1" fontWeight="bold">
                Antecedentes Penales
              </Typography>
              {isEdit && (
                <a
                  href={`${url}api/user/lofty/file/lofty/local/${elementEdit.penalRecord}`}
                  className="btn btn-transparent btn-sm"
                  target="_blank"
                >
                  <CloudDownloadIcon />
                </a>
              )}
            </Box>
            <Input id="penalRecord" name="penalRecord" placeholder="Antecedentes Penales" type="file" />
          </div>
          <div className="form-input-container">
            <Box mb={1} ml={0.5}>
              <Typography component="label" variant="subtitle1" fontWeight="bold">
                Antecedentes Policiales
              </Typography>
              {isEdit && (
                <a
                  href={`${url}api/user/lofty/file/lofty/local/${elementEdit.policeRecord}`}
                  className="btn btn-transparent btn-sm"
                  target="_blank"
                >
                  <CloudDownloadIcon />
                </a>
              )}
            </Box>
            <Input id="policeRecord" name="policeRecord" placeholder="Antecedentes Policiales" type="file" />
          </div>
          <div className="form-input-container">
            <Box mb={1} ml={0.5}>
              <Typography component="label" variant="subtitle1" fontWeight="bold">
                Fotografía de Perfil
              </Typography>
              {isEdit && elementEdit?.profilePicture && (
                <a
                  href={`${url}api/user/lofty/file/lofty/local/${elementEdit.profilePicture}`}
                  className="btn btn-transparent btn-sm"
                  target="_blank"
                >
                  <CloudDownloadIcon />
                </a>
              )}
            </Box>
            <Input id="profilePicture" name="profilePicture" placeholder="Fotografía de Perfil" type="file" />
          </div>
          <div className="form-input-container">
            <Box mb={1} ml={0.5} width={"100%"}>
              <Typography component="label" variant="subtitle1" fontWeight="bold">
                Estado
              </Typography>
            </Box>
            <Autocomplete
              disablePortal
              id="status"
              // getOptionLabel={option => (option.label || selectedType)}
              isOptionEqualToValue={(option, value) => (option.label || statusInfo)}
              options={stateData}
              renderInput={(params) => <TextField {...params} />}
              defaultValue={statusInfo}
              onChange={(e, value) => {
                if (value?.name) {
                  setSelectedStatus(value.name);
                }
              }}
              renderOption={(props, option) => {
                return (
                  <li {...props}>
                    {option.label}
                  </li>
                )
              }}
            />
          </div>
          {selectedStatus === 'document_pending' && (
            <div className="form-input-container">
              <Box mb={1} ml={0.5}>
                <Typography component="label" variant="subtitle1" fontWeight="bold">
                  Comentario
                </Typography>
                <Input className="full-width" id="comments" name="comments" placeholder="Comentario" defaultValue={elementEdit.comments}
                  inputProps={{
                    maxLength: 500,
                    step: "1"
                  }}
                />
              </Box>
            </div>
          )
          }
        </div>
      </form>
    </DialogContent>
    <DialogActions style={{ paddingRight: '1rem', paddingBottom: '1rem' }}>
      <button className="btn btn-primary" form="element-form" type="submit">
        <Typography variant="button" fontWeight="bold" textTransform="capitalize" color="white">
          {isEdit ? 'EDITAR' : 'AGREGAR'}
        </Typography>
      </button>
    </DialogActions>
  </Dialog>
)

const Users = ({ token }) => {
  moment.locale('es-mx');
  let [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const [showAddModal, setShowAddModal] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [elementEdit, setElementEdit] = useState(false);
  const [users, setUsers] = useState([]);
  const [counterPage, setCounterPage] = useState(1);
  const [selectedStatus, setSelectedStatus] = useState('');
  // const [currentPage, setCurrentPage] = useState(1);
  // const [limitData, setLimitData] = useState(10);
  const currentPage = parseInt(searchParams.get('page'));
  const limitData = parseInt(searchParams.get('count'));
  const stateData = [
    {name: 'pending', label: 'Pendiente'},
    {name: 'approved', label: 'Aprobado'},
    {name: 'rejected', label: 'Rechazado'},
    {name: 'document_pending', label: 'Pendiente de Documentacion'},
  ]
  const loadElements = async () => {
    try {
      const res = await listUsers({ token, page: currentPage, count: limitData });
      setUsers(res.data);
      setCounterPage(Math.ceil(res.paginateInfo.totalPages));
    } catch (error) {
      toastr.error('Error al obtener los datos');
    }
  }

  const seekNewData = async (value) => {
    try {
      // setCurrentPage(page);
      setSearchParams({ page: value, count: limitData });
      const res = await listUsers({ token, page: value, count: limitData });
      setUsers(res.data);
      setCounterPage(Math.ceil(res.paginateInfo.totalPages));
    } catch (error) {
      toastr.error('Error al obtener los datos');
    }
  }

  const stateNewLimitPage = async (limiter) => {
    try {
      setSearchParams({ page: 1, count: limiter });
      // setCurrentPage(1);
      const res = await listUsers({ token, page: 1, count: limiter });
      setUsers(res.data);
      setCounterPage(Math.ceil(res.paginateInfo.totalPages));
    } catch (error) {
      toastr.error('Error al obtener los datos');
    }
  }

  const formatDateMoment = (createdDate) => {
    return moment(createdDate).format('YYYY MMMM DD, h:mm:ss a')
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    const newElement = {
      loftyEmail: e.target.email.value,
      loftyName: e.target.loftyName.value,
      phone: e.target.phone.value,
      dni: e.target.dni.value,
      dniImage: e.target.dniImage.files[0],
      driversLicense: e.target.driversLicense.files[0],
      appProfile: e.target.appProfile.files[0],
      penalRecord: e.target.penalRecord.files[0],
      policeRecord: e.target.policeRecord.files[0],
      profilePicture: e.target.profilePicture.files[0],
      status: selectedStatus,
      comments: e?.target?.comments?.value || "",
    };
    try {
      let response;
      if (isEdit) {
        response = await updateUser({ token, ...newElement, _id: elementEdit._id });
        setShowAddModal(false);
      } else {
        response = await createUser({ token, ...newElement });
      }
      toastr.success(response?.info);
      loadElements();
      document.querySelector('#element-form').reset();
    } catch (error) {
      console.log(error)
      toastr.error(error?.info || error?.response?.data?.info);
    }
  }

  const showAdd = () => {
    setShowAddModal(true);
    setIsEdit(false);
    setElementEdit({});
  }

  const showEdit = (element) => {
    setShowAddModal(true);
    setIsEdit(true);
    setElementEdit(element);
    setSelectedStatus(element.status);
  }

  useEffect(() => {
    loadElements();
  }, [])

  const getStatusFromValue = (element) => {
    try {
      const findStatus = stateData.find((index) => (index.name === element));
      return findStatus.label;
    } catch (error) {
      return '';
    }
  }


  return (
    <div>
      <div className="section-title-container">
        <h4>
          Usuarios
        </h4>
        <button className="btn btn-primary" onClick={showAdd}>
          Agregar
        </button>
      </div>
      <div className="card card-body">
        <div className="table-responsive">
          <table className="table table-hover">
            <thead>
              <tr>
                <th>Acciones</th>
                <th>Nombre Completo</th>
                <th>Email</th>
                <th>DNI</th>
                <th>Teléfono</th>
                <th>Estado</th>
                <th>Creado en</th>
              </tr>
            </thead>
            <tbody>
              {users.map(element => (
                <tr>
                  <td className="text-danger">
                    <button className="btn btn-primary btn-rounded btn-sm" onClick={() => showEdit(element)}>
                      Editar
                    </button>
                    <button className="btn btn-primary btn-rounded btn-sm" onClick={() => {
                      navigate(`/users/metrics/${element._id}`);
                    }}>
                      Métricas
                    </button>
                    <button className="btn btn-primary btn-rounded btn-sm" onClick={() => {
                      navigate(`/users/request/${element._id}`);
                    }}>
                      Solicitudes
                    </button>
                  </td>
                  <td>{element.loftyName}</td>
                  <td>{element.loftyEmail}</td>
                  <td>{element.dni}</td>
                  <td>{element.phone}</td>
                  <td>{getStatusFromValue(element.status)}</td>
                  <td>{formatDateMoment(element.loftyCreatedAt)}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
      <div style={{ display: 'flex', width: '100%', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-end' }}>
        {currentPage > 1 && (
          <button className="btn btn-primary btn-rounded btn-sm" style={{ margin: '5px' }} onClick={() => seekNewData(currentPage - 1)}>
            Previo
          </button>
        )
        }
        <div style={{ margin: '5px' }}>
          Pag: {currentPage} / {counterPage}
        </div>
        {currentPage < counterPage && (
          <button className="btn btn-primary btn-rounded btn-sm" style={{ margin: '5px' }} onClick={() => seekNewData(currentPage + 1)}>
            Siguiente
          </button>
        )}
      </div>
      <div style={{ display: 'flex', width: '100%', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-end' }}>
        <TextField defaultValue={limitData} onChange={(e) => stateNewLimitPage(e.target.value)} select name="rowsPerPage" type="rowsPerPage" label="Datos por Pagina:" variant="standard">
          <MenuItem value={10} key={10}>
            10 por Pagina
          </MenuItem>
          <MenuItem value={20} key={20}>
            20 por Pagina
          </MenuItem>
          <MenuItem value={50} key={50}>
            50 por Pagina
          </MenuItem>
        </TextField>
      </div>
      <AddOrEditModal
        showAddModal={showAddModal}
        setShowAddModal={setShowAddModal}
        handleSubmit={handleSubmit}
        isEdit={isEdit}
        elementEdit={elementEdit}
        stateData={stateData}
        statusInfo={getStatusFromValue(elementEdit.status)}
        setSelectedStatus={setSelectedStatus}
        selectedStatus={selectedStatus}
      />
    </div>
  )
}

const mapStateToProps = (state) => state.auth;

export default connect(mapStateToProps)(Users);
