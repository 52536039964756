/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Box,
  Typography,
  Input,
  TextField,
  MenuItem,
  Select,
} from "@mui/material";
import moment from 'moment';
import './Companies.css';
import { useState } from 'react';
import { connect } from 'react-redux';
import toastr from 'toastr';
import { createCompany, listCompanies, updateCompany } from '../../../api/company.api';
import 'moment/locale/es-mx';

const AddOrEditModal = ({
  showAddModal,
  setShowAddModal,
  handleSubmit,
  isEdit,
  elementEdit,
}) => (
  <Dialog open={showAddModal} onClose={() => setShowAddModal(false)} fullWidth maxWidth="lg">
    <DialogTitle>{isEdit ? 'Editar' : 'Nueva'} Empresa</DialogTitle>
    <DialogContent>
      <form id="element-form" onSubmit={(e) => handleSubmit(e)}>
        <div className="form-main-container">
          <div className="form-input-container">
            <Box mb={1} ml={0.5} width={"100%"}>
              <Typography component="label" variant="subtitle1" fontWeight="bold">
                RTN
              </Typography>
            </Box>
            <Input inputProps={{ maxLength: 14 }} className="full-width" id="rtn" name="rtn" placeholder="08011990123456" defaultValue={elementEdit.rtn} />
          </div>
          <div className="form-input-container">
            <Box mb={1} ml={0.5} width={"100%"}>
              <Typography component="label" variant="subtitle1" fontWeight="bold">
                Nombre de la Empresa
              </Typography>
            </Box>
            <Input inputProps={{ maxLength: 500 }} className="full-width" id="name" name="name" placeholder="Inversiones La Paz, Pollos Clara, etc..." defaultValue={elementEdit.name} />
          </div>
          <div className="form-input-container">
            <Box mb={1} ml={0.5} width={"100%"}>
              <Typography component="label" variant="subtitle1" fontWeight="bold">
                Nombre del Representante
              </Typography>
            </Box>
            <Input inputProps={{ maxLength: 100 }} className="full-width" id="representativename" name="representativename" placeholder="Ricardo Alonso" defaultValue={elementEdit.representativename} />
          </div>
          <div className="form-input-container">
            <Box mb={1} ml={0.5} width={"100%"}>
              <Typography component="label" variant="subtitle1" fontWeight="bold">
                Email
              </Typography>
            </Box>
            <Input className="full-width" id="email" name="email" placeholder="campero@mail.com" defaultValue={elementEdit.email} type="email"
              inputProps={{ maxLength: 100 }}
            />
          </div>
          <div className="form-input-container">
            <Box mb={1} ml={0.5} width={"100%"}>
              <Typography component="label" variant="subtitle1" fontWeight="bold">
                Teléfono del representante
              </Typography>
            </Box>
            <Input className="full-width" id="representativephone" name="representativephone" placeholder="+50489892020" defaultValue={elementEdit.representativephone}
              inputProps={{ maxLength: 14 }}
            />
          </div>
          <div className="form-input-container">
            <Box mb={1} ml={0.5} width={"100%"}>
              <Typography component="label" variant="subtitle1" fontWeight="bold">
                Dirección
              </Typography>
            </Box>
            <Input className="full-width" id="direction" name="direction" placeholder="Col. Las Minitas..." defaultValue={elementEdit.direction}
              inputProps={{ maxLength: 2000 }}
            />
          </div>
          {/* <div className="form-input-container">
            <Box mb={1} ml={0.5} width={"100%"}>
              <Typography component="label" variant="subtitle1" fontWeight="bold">
                Fecha de Inicio de Contrato
              </Typography>
            </Box>
            <Input type="date" className="full-width" id="contractstartdate" name="contractstartdate" placeholder="Col. Las Minitas..." defaultValue={elementEdit.contractstartdate} />
          </div> */}
          <div className="form-input-container">
            <Box mb={1} ml={0.5} width={"100%"}>
              <Typography component="label" variant="subtitle1" fontWeight="bold">
                Estado
              </Typography>
            </Box>
            <TextField className="full-width" defaultValue={elementEdit.status} select name="status" type="status" label="Estado:" variant="standard">
              <MenuItem value={'inactive'}>Inactivo</MenuItem>
              <MenuItem value={'active'}>Activo</MenuItem>
            </TextField>
          </div>
        </div>
      </form>
    </DialogContent>
    <DialogActions style={{ paddingRight: '1rem', paddingBottom: '1rem' }}>
      <button className="btn btn-primary" form="element-form" type="submit">
        <Typography variant="button" fontWeight="bold" textTransform="capitalize" color="white">
          {isEdit ? 'EDITAR' : 'AGREGAR'}
        </Typography>
      </button>
    </DialogActions>
  </Dialog>
)

const Companies = ({ token }) => {
  moment.locale('es-mx');
  let [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const [showAddModal, setShowAddModal] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [elementEdit, setElementEdit] = useState(false);
  const [users, setUsers] = useState([]);
  const [counterPage, setCounterPage] = useState(1);
  // const [currentPage, setCurrentPage] = useState(1);
  // const [limitData, setLimitData] = useState(10);
  const currentPage = parseInt(searchParams.get('page'));
  const limitData = parseInt(searchParams.get('count'));

  const loadElements = async () => {
    try {
      const res = await listCompanies({ token, page: currentPage, count: limitData });
      setUsers(res.data);
      setCounterPage(Math.ceil(res.paginateInfo.totalPages));
    } catch (error) {
      toastr.error('Error al obtener los datos');
    }
  }

  const seekNewData = async (value) => {
    try {
      // setCurrentPage(page);
      setSearchParams({ page: value, count: limitData });
      const res = await listCompanies({ token, page: value, count: limitData });
      setUsers(res.data);
      setCounterPage(Math.ceil(res.paginateInfo.totalPages));
    } catch (error) {
      toastr.error('Error al obtener los datos');
    }
  }

  const stateNewLimitPage = async (limiter) => {
    try {
      setSearchParams({ page: 1, count: limiter });
      // setCurrentPage(1);
      const res = await listCompanies({ token, page: 1, count: limiter });
      setUsers(res.data);
      setCounterPage(Math.ceil(res.paginateInfo.totalPages));
    } catch (error) {
      toastr.error('Error al obtener los datos');
    }
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    const newElement = {
      rtn: e.target.rtn.value,
      name: e.target.name.value,
      representativename: e.target.representativename.value,
      email: e.target.email.value,
      representativephone: e.target.representativephone.value,
      direction: e.target.direction.value,
      contractstartdate: new Date(),
      status: e.target.status.value,
    };
    try {
      let response;
      if (isEdit) {
        response = await updateCompany({ token, ...newElement, _id: elementEdit._id });
        setShowAddModal(false);
      } else {
        response = await createCompany({ token, ...newElement });
      }
      toastr.success(response?.info);
      loadElements();
      document.querySelector('#element-form').reset();
    } catch (error) {
      console.log(error)
      toastr.error(error?.info || error?.response?.data?.info);
    }
  }

  const showAdd = () => {
    setShowAddModal(true);
    setIsEdit(false);
    setElementEdit({});
  }

  const showEdit = (element) => {
    setShowAddModal(true);
    setIsEdit(true);
    setElementEdit(element);
  }

  useEffect(() => {
    loadElements();
  }, [])

  const formatDateMoment = (createdDate) => {
    return moment(createdDate).format('YYYY MMMM DD, h:mm:ss a')
  }

  const translateState = (state) => {
    if (state === 'active') {
      return 'activo';
    }
    return 'inactivo';
  }

  return (
    <div>
      <div className="section-title-container">
        <h4>
          Empresas
        </h4>
        <button className="btn btn-primary" onClick={showAdd}>
          Agregar
        </button>
      </div>
      <div className="card card-body">
        <div className="table-responsive">
          <table className="table table-hover">
            <thead>
              <tr>
                <th>Acciones</th>
                <th>RTN</th>
                <th>Nombre</th>
                <th>Nombre de Representante</th>
                <th>Email</th>
                <th>Teléfono de Representante</th>
                <th>Dirección</th>
                <th>Fecha de Inicio del Contrato</th>
                <th>Estado</th>
                <th>Creado en</th>
              </tr>
            </thead>
            <tbody>
              {users.map(element => (
                <tr>
                  <td className="text-danger">
                    <button className="btn btn-primary btn-rounded btn-sm" onClick={() => showEdit(element)}>
                      Editar
                    </button>
                    <button className="btn btn-primary btn-rounded btn-sm" onClick={() => {
                      navigate(`/companies/metrics/${element._id}`)
                    }}>
                      Métricas
                    </button>
                    <button className="btn btn-primary btn-rounded btn-sm" onClick={() => {
                      navigate(`/companies/contacts/${element._id}`)
                    }}>
                      Más contactos
                    </button>
                  </td>
                  <td>{element.rtn}</td>
                  <td>{element.name}</td>
                  <td>{element.representativename}</td>
                  <td>{element.email}</td>
                  <td>{element.representativephone}</td>
                  <td>{element.direction}</td>
                  <td>{new Date(element.contractstartdate).toLocaleDateString('es-MX')}</td>
                  <td>{translateState(element.status)}</td>
                  <td>{formatDateMoment(element.loftyCreatedAt)}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
      <div style={{ display: 'flex', width: '100%', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-end' }}>
        {currentPage > 1 && (
          <button className="btn btn-primary btn-rounded btn-sm" style={{ margin: '5px' }} onClick={() => seekNewData(currentPage - 1)}>
            Previo
          </button>
        )
        }
        <div style={{ margin: '5px' }}>
          Pag: {currentPage} / {counterPage}
        </div>
        {currentPage < counterPage && (
          <button className="btn btn-primary btn-rounded btn-sm" style={{ margin: '5px' }} onClick={() => seekNewData(currentPage + 1)}>
            Siguiente
          </button>
        )}
      </div>
      <div style={{ display: 'flex', width: '100%', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-end' }}>
        <TextField defaultValue={limitData} onChange={(e) => stateNewLimitPage(e.target.value)} select name="rowsPerPage" type="rowsPerPage" label="Datos por Pagina:" variant="standard">
          <MenuItem value={10} key={10}>
            10 por Pagina
          </MenuItem>
          <MenuItem value={20} key={20}>
            20 por Pagina
          </MenuItem>
          <MenuItem value={50} key={50}>
            50 por Pagina
          </MenuItem>
        </TextField>
      </div>
      <AddOrEditModal
        showAddModal={showAddModal}
        setShowAddModal={setShowAddModal}
        handleSubmit={handleSubmit}
        isEdit={isEdit}
        elementEdit={elementEdit}
      />
    </div>
  )
}

const mapStateToProps = (state) => state.auth;

export default connect(mapStateToProps)(Companies);
