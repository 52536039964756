import axios from '../config/Axios';

export const url = '/api/logcampaign';

export const metricsVisutalizationVideo = ({
  token,
  queryFirstInitialDate,
  queryFirstEndInitalDate,
  querySecondInitialDate,
  querySecondEndDate,
  userid,
  idCampaing,
  idAdverising,
  idCompany,
  type,
}) => new Promise((resolve, reject) => {
  if (token) {
    axios.get(`${url}/metrics`, {
      headers: {
        AuthorizationApp: token
      },
      params: {
        queryFirstInitialDate,
        queryFirstEndInitalDate,
        querySecondInitialDate,
        querySecondEndDate,
        userid,
        idCampaing,
        idAdverising,
        idCompany,
        type,
      }
    }).then((res) => {
      if (res.data.status === 'success') {
        resolve(res.data);
      } else {
        reject(res.data);
      }
    }).catch((error) => {
      reject(error?.response?.data);
    });
  } else {
    reject({
      status: 'error',
      info: 'Token no definido'
    });
  }
});

export const totalMetricsLogCampaign = ({
  token,
  queryFirstInitialDate,
  queryFirstEndInitalDate,
  querySecondInitialDate,
  querySecondEndDate,
  userid,
  idCampaing,
  idAdverising,
  idCompany,
  type,
}) => new Promise((resolve, reject) => {
  if (token) {
    axios.get(`${url}/total`, {
      headers: {
        AuthorizationApp: token
      },
      params: {
        queryFirstInitialDate,
        queryFirstEndInitalDate,
        querySecondInitialDate,
        querySecondEndDate,
        userid,
        idCampaing,
        idAdverising,
        idCompany,
        type,
      }
    }).then((res) => {
      if (res.data.status === 'success') {
        resolve(res.data);
      } else {
        reject(res.data);
      }
    }).catch((error) => {
      reject(error?.response?.data);
    });
  } else {
    reject({
      status: 'error',
      info: 'Token no definido'
    });
  }
});
