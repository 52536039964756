import axios from '../config/Axios';

export const url = '/api/tablet';

export const createTablet = ({
  token,
  imei,
  tabletmodel,
  tabletbrand,
  gbstorage,
  gibram,
  state10,
  statedetails,
  status,
  telephone,
}) => new Promise((resolve, reject) => {
  if (
    imei &&
    tabletmodel &&
    tabletbrand &&
    gbstorage &&
    gibram &&
    state10 &&
    statedetails
  ) {
    axios.post(`${url}/create/basic/`, {
      token,
      imei,
      tabletmodel,
      tabletbrand,
      gbstorage,
      gibram,
      state10,
      statedetails,
      telephone,
    }, {
      headers: {
        AuthorizationApp: token,
      },
    }).then((res) => {
      const { data } = res;
      if (data.status === 'success') {
        resolve(data);
      } else {
        reject(data);
      }
    }).catch((error) => reject(error));
  } else if (!imei) {
    reject({
      status: 'error',
      info: 'IMEI no definido',
    });
  } else if (!tabletmodel) {
    reject({
      status: 'error',
      info: 'Modelo de Tablet no definido',
    });
  } else if (!tabletbrand) {
    reject({
      status: 'error',
      info: 'Marca de tablet no definida',
    });
  } else if (!gbstorage) {
    reject({
      status: 'error',
      info: 'Almacenamiento no definido',
    });
  } else if (!gibram) {
    reject({
      status: 'error',
      info: 'Memoria RAM no definida',
    });
  } else if (!state10) {
    reject({
      status: 'error',
      info: 'Estado Fisico no definido',
    });
  } else if (!statedetails) {
    reject({
      status: 'error',
      info: 'Detalles físicos no definidos',
    });
  } else {
    reject({
      status: 'error',
      info: 'Error al guardar el dato',
    });
  }
});

export const updateTablet = ({
  token,
  _id,
  userId,
  imei,
  tabletmodel,
  tabletbrand,
  gbstorage,
  gibram,
  state10,
  statedetails,
  status,
  telephone,
}) => new Promise((resolve, reject) => {
  axios.put(`${url}/update/basic/${_id}`, {
    userId,
    imei,
    tabletmodel,
    tabletbrand,
    gbstorage,
    gibram,
    state10,
    statedetails,
    status,
    telephone,
  }, {
    headers: {
      AuthorizationApp: token,
    },
  }).then((res) => {
    const { data } = res;
    if (data.status === 'success') {
      resolve(data);
    } else {
      reject(data);
    }
  }).catch((error) => reject(error));
});

export const listTablets = ({ token, page, count }) => new Promise((resolve, reject) => {
  if (token) {
    axios.get(`${url}/list/basic/`, {
      headers: {
        AuthorizationApp: token,
      },
      params: {
        page,
        count,
      },
    }).then((res) => {
      const { data } = res;
      if (data.status === 'success') {
        resolve(data);
      } else {
        reject(data);
      }
    }).catch((error) => reject(error));
  } else {
    reject({
      status: 'error',
      info: 'Token no definido',
    });
  }
});

export const getUserTablets = ({ token }) => new Promise((resolve, reject) => {
  if (token) {
    axios.get(`${url}/get/status/`, {
      headers: {
        AuthorizationApp: token,
      },
    }).then((res) => {
      const { data } = res;
      if (data.status === 'success') {
        resolve(data);
      } else {
        reject(data);
      }
    }).catch((error) => reject(error));
  } else {
    reject({
      status: 'error',
      info: 'Token no definido',
    });
  }
});

export const sendPlayCommandApi = ({ token, newPlayStatus }) => new Promise((resolve, reject) => {
  if (token) {
    axios.get(`${url}/command/play/${newPlayStatus}`, {
      headers: {
        AuthorizationApp: token,
      },
    }).then((res) => {
      const { data } = res;
      if (data.status === 'success') {
        resolve(data);
      } else {
        reject(data);
      }
    }).catch((error) => reject(error));
  } else {
    reject({
      status: 'error',
      info: 'Token no definido',
    });
  }
});

