import axios from '../config/Axios';

export const url = '/api/reset/lofty';


export const createResetToken = ({ email }) => new Promise((resolve, reject) => {
  if (email) {
    axios.post(`${url}/create`, {
      email,
    }).then((res) => {
      const { data } = res;
      if (data.status === 'success') {
        resolve(data);
      } else {
        reject(data);
      }
    }).catch((error) => reject(error));
  } else {
    reject({
      status: 'error',
      info: 'Email no definido',
    });
  }
});


export const resetPassword = ({ password, confirmPassword, token  }) => new Promise((resolve, reject) => {
  if (password && confirmPassword && token) {
    axios.post(`${url}/password`, {
      password,
      confirmPassword,
      token,
    }).then((res) => {
      const { data } = res;
      if (data.status === 'success') {
        resolve(data);
      } else {
        reject(data);
      }
    }).catch((error) => reject(error));
  } else if (!password) {
    reject({
      status: 'error',
      info: 'Contraseña no definida',
    });
  } else if (!confirmPassword) {
    reject({
      status: 'error',
      info: 'Necesita confirmar contraseña',
    });
  } else {
    reject({
      status: 'error',
      info: 'Token no definido',
    });
  }
});