import axios from '../config/Axios';

const url = '/api/tabletxvehicle';

export const createTabletVehicle = ({
  token,
  vehicle,
  tablet,
}) => new Promise((resolve, reject) => {
  if (token && vehicle && tablet) {
    axios.post(`${url}/create/basic/`, {
      vehicle,
      tablet,
    }, {
      headers: {
        AuthorizationApp: token,
      },
    }).then((res) => {
      if (res.data.status === 'success') {
        resolve(res.data);
      } else {
        reject(res.data);
      }
    }).catch((error) => {
      reject(error.response?.data);
    })
  } else if (!token) {
    return reject({
      status: 'error',
      info: 'Token no definido'
    });
  } else if (!vehicle) {
    return reject({
      status: 'error',
      info: 'Vehiculo no definido'
    });
  } else {
    return reject({
      status: 'error',
      info: 'Tablet no definida'
    });
  }
})

export const updateTabletVehicle = ({
  token,
  vehicle,
  tablet,
  _id,
}) => new Promise((resolve, reject) => {
  if (token && _id && vehicle && tablet) {
    axios.put(`${url}/update/basic/${_id}`, {
      vehicle,
      tablet,
    }, {
      headers: {
        AuthorizationApp: token,
      },
    }).then((res) => {
      if (res.data.status === 'success') {
        resolve(res.data);
      } else {
        reject(res.data);
      }
    }).catch((error) => {
      reject(error.response?.data);
    })
  } else if (!token) {
    return reject({
      status: 'error',
      info: 'Token no definido'
    });
  } else if (!vehicle) {
    return reject({
      status: 'error',
      info: 'Vehiculo no definido'
    });
  } else if (!tablet) {
    return reject({
      status: 'error',
      info: 'Tablet no definida'
    });
  } else {
    return reject({
      status: 'error',
      info: 'Identificador de referidos no definido'
    });
  }
});

export const getAllTabletVehicle = ({ token, page, count }) => new Promise((resolve, reject) => {
  if (token) {
    axios.get(`${url}/list/basic/`, {
      headers: {
        AuthorizationApp: token,
      },
      params: {
        page,
        count,
      },
    }).then((res) => {
      const { data } = res;
      if (data.status === 'success') {
        resolve(data);
      } else {
        reject(data);
      }
    }).catch((error) => reject(error));
  } else {
    reject({
      status: 'error',
      info: 'Token no definido',
    });
  }
});

export const deleteTabletVehicle = ({token, _id}) => new Promise((resolve, reject) => {
  if (token && _id) {
    axios.delete(`${url}/list/basic/`, {
      headers: {
        AuthorizationApp: token,
      },
    }).then((res) => {
      const { data } = res;
      if (data.status === 'success') {
        resolve(data);
      } else {
        reject(data);
      }
    }).catch((error) => reject(error));
  } else if (!_id) {
    reject({
      status: 'error',
      info: 'Identificador no definido',
    });
  } else {
    reject({
      status: 'error',
      info: 'Token no definido',
    });
  }
})