import axios from '../config/Axios';

const url = '/api/plans';

export const createPlans = ({
  token, slug, label, price, currency, numberAdvertising, minSeconds, maxSeconds,
  changeNumber,
}) => new Promise((resolve, reject) => {
  if (token && slug  && label && price && currency && numberAdvertising && minSeconds && maxSeconds && changeNumber) {
    axios.post(`${url}/create`, {
      slug, label, price, currency, numberAdvertising, minSeconds, maxSeconds,
      changeNumber,
    }, {
      headers: {
        AuthorizationApp: token
      }
    }).then((res) => {
      if (res.data.status === 'success') {
        resolve(res.data);
      } else {
        reject(res.data)
      }
    }).catch((error) => {
      reject(error?.response?.data);
    });
  } else if (!slug) {
    reject({
      status: 'error',
      info: 'Slug no definido'
    });
  } else if (!label) {
    reject({
      status: 'error',
      info: 'Etiqueta no definido'
    });
  } else if (!price || price <= 0) {
    reject({
      status: 'error',
      info: 'Precio no definido, recuerde que debe ser mayor que 0'
    });
  } else if (!currency) {
    reject({
      status: 'error',
      info: 'Moneda no definida',
    });
  } else if (!numberAdvertising) {
    reject({
      status: 'error',
      info: 'Número de anuncios no definido'
    });
  } else if (!minSeconds || minSeconds <= 0) {
    reject({
      status: 'error',
      info: 'Minimo de segundos no definido, recuerde que debe ser mayor que 0'
    });
  } else if (!maxSeconds || maxSeconds <= 0) {
    reject({
      status: 'error',
      info: 'Máximo de segundos no definido, recuerde que debe ser mayor que 0'
    });
  } else if (!changeNumber || changeNumber <= 0) {
    reject({
      status: 'error',
      info: 'Numero de cambios no definido, recuerde que debe ser mayor que 0'
    });
  } else {
    reject({
      status: 'error',
      info: 'Token no definido'
    });
  }
});

export const updatePlans = ({
  token, _id, slug, label, price, currency, numberAdvertising, minSeconds, maxSeconds,
  changeNumber,
}) => new Promise((resolve, reject) => {
  if (token && _id && slug && label && price && price && currency && numberAdvertising && minSeconds && maxSeconds && changeNumber) {
    axios.put(`${url}/update/${_id}`, {
      slug, label, price, currency, numberAdvertising, minSeconds, maxSeconds,
      changeNumber,
    }, {
      headers: {
        AuthorizationApp: token
      }
    }).then((res) => {
      if (res.data.status === 'success') {
        resolve(res.data);
      } else {
        reject(res.data);
      }
    }).catch((error) => {
      reject(error?.response?.data);
    });
  } else if (!_id) {
    reject({
      status: 'error',
      info: 'Identificador no definido'
    });
  } else if (!slug) {
    reject({
      status: 'error',
      info: 'Slug no definido'
    });
  } else if (!label) {
    reject({
      status: 'error',
      info: 'Etiqueta no definido'
    });
  } else if (!price || price <= 0) {
    reject({
      status: 'error',
      info: 'Precio no definido, recuerde que debe ser mayor que 0'
    });
  } else if (!currency) {
    reject({
      status: 'error',
      info: 'Moneda no definida',
    });
  } else if (!numberAdvertising) {
    reject({
      status: 'error',
      info: 'Número de anuncios no definido'
    });
  } else if (!minSeconds || minSeconds <= 0) {
    reject({
      status: 'error',
      info: 'Minimo de segundos no definido, recuerde que debe ser mayor que 0'
    });
  } else if (!maxSeconds || maxSeconds <= 0) {
    reject({
      status: 'error',
      info: 'Máximo de segundos no definido, recuerde que debe ser mayor que 0'
    });
  } else if (!changeNumber || changeNumber <= 0) {
    reject({
      status: 'error',
      info: 'Numero de cambios no definido, recuerde que debe ser mayor que 0'
    });
  } else {
    reject({
      status: 'error',
      info: 'Token no definido'
    });
  }
});

export const listPlans = ({
  token
}) => new Promise((resolve, reject) => {
  if (token) {
    axios.get(`${url}/list`, {
      headers: {
        AuthorizationApp: token
      }
    }).then((res) => {
      if (res.data.status === 'success') {
        resolve(res.data);
      } else {
        reject(res.data);
      }
    }).catch((error) => {
      reject(error?.response?.data);
    });
  } else {
    reject({
      status: 'error',
      info: 'Token no definido'
    });
  }
});

export const removePlans = ({
  token, _id
}) => new Promise((resolve, reject) => {
  if (token && _id) {
    axios.delete(`${url}/remove/${_id}`, {
      headers: {
        AuthorizationApp: token
      }
    }).then((res) => {
      if (res.data.status === 'success') {
        resolve(res.data);
      } else {
        reject(res.data);
      }
    }).catch((error) => {
      reject(error?.response?.data);
    });
  } else if (!token) {
    reject({
      status: 'error',
      info: 'Token no definido'
    });
  } else {
    reject({
      status: 'error',
      info: 'Identificador no definido'
    });
  }
});
