import React, { Component } from 'react';
import {
  Box,
  Typography,
  Input,
} from "@mui/material";
import { createUserWebForm } from '../../api/user.api';

export class Register extends Component {
  render() {
    return (
      <div>
        <div className="d-flex align-items-center auth px-0">
          <div className="row w-100 mx-0">
            <div className="col-lg-4 mx-auto">
              <div className="auth-form-light text-left py-5 px-4 px-sm-5">
                <h2 className="text-center">¿Régistrate como conductor?</h2>
                <div className="brand-logo" style={{display: 'flex', justifyContent: 'center'}}>
                  <img src={'https://dicipgo.com/assets/img/logo/logo.png'} alt="logo" />
                </div>
                <form
                  id="element-form"
                  className="mt-4"
                  onSubmit={async (e) => {
                    try {
                      e.preventDefault();
                      const newElement = {
                        loftyEmail: e.target.email.value,
                        loftyName: e.target.loftyName.value,
                        phone: e.target.phone.value,
                        dni: e.target.dni.value,
                        dniImage: e.target.dniImage.files[0],
                        driversLicense: e.target.driversLicense.files[0],
                        appProfile: e.target.appProfile.files[0],
                        penalRecord: e.target.penalRecord.files[0],
                        policeRecord: e.target.policeRecord.files[0],
                      };
                      await createUserWebForm(newElement);
                      alert('Se ha registrado de manera exitosa')
                    } catch (error) {
                      alert('Error al registrase, si el error persiste contacte a info@dicipgo.com');
                    }
                  }}
                >
                  <div className="">
                    <div className="mb-4">
                      <Box mb={1} ml={0.5} width={"100%"}>
                        <Typography component="label" variant="subtitle1" fontWeight="bold">
                          Nombre Completo
                        </Typography>
                      </Box>
                      <Input className="full-width" id="loftyName" name="loftyName" placeholder="Nombre Completo"
                        inputProps={{
                          maxLength: 500,
                          step: "1"
                        }}
                      />
                    </div>
                    <div className="mb-4">
                      <Box mb={1} ml={0.5} width={"100%"}>
                        <Typography component="label" variant="subtitle1" fontWeight="bold">
                          Email
                        </Typography>
                      </Box>
                      <Input className="full-width" id="email" name="email" placeholder="Email"
                        type="email"
                        inputProps={{
                          maxLength: 500,
                          step: "1"
                        }}
                      />
                    </div>
                    <div className="mb-4">
                      <Box mb={1} ml={0.5} width={"100%"}>
                        <Typography component="label" variant="subtitle1" fontWeight="bold">
                          Teléfono
                        </Typography>
                      </Box>
                      <Input className="full-width" id="phone" name="phone" placeholder="Teléfono"
                        inputProps={{
                          maxLength: 50,
                          step: "1"
                        }}
                      />
                    </div>
                    <div className="mb-4">
                      <Box mb={1} ml={0.5} width={"100%"}>
                        <Typography component="label" variant="subtitle1" fontWeight="bold">
                          DNI
                        </Typography>
                      </Box>
                      <Input className="full-width" id="dni" name="dni" placeholder="0801123443212"
                        inputProps={{
                          maxLength: 20,
                          step: "1"
                        }}
                      />
                    </div>
                    <div className="mb-4">
                      <Box mb={1} ml={0.5}>
                        <Typography component="label" variant="subtitle1" fontWeight="bold">
                          Imagen DNI
                        </Typography>
                      </Box>
                      <Input id="dniImage" name="dniImage" placeholder="Imagen DNI" type="file" />
                    </div>
                    <div className="mb-4">
                      <Box mb={1} ml={0.5}>
                        <Typography component="label" variant="subtitle1" fontWeight="bold">
                          Licencia de Conducción
                        </Typography>
                      </Box>
                      <Input id="driversLicense" name="driversLicense" placeholder="Licencia de Conducción" type="file" />
                    </div>
                    <div className="mb-4">
                      <Box mb={1} ml={0.5}>
                        <Typography component="label" variant="subtitle1" fontWeight="bold">
                          Captura de Perfil de Aplicación
                        </Typography>
                      </Box>
                      <Input id="appProfile" name="appProfile" placeholder="Uber, InDrive, etc..." type="file" />
                    </div>
                    <div className="mb-4">
                      <Box mb={1} ml={0.5}>
                        <Typography component="label" variant="subtitle1" fontWeight="bold">
                          Antecedentes Penales
                        </Typography>
                      </Box>
                      <Input id="penalRecord" name="penalRecord" placeholder="Antecedentes Penales" type="file" />
                    </div>
                    <div className="mb-4">
                      <Box mb={1} ml={0.5}>
                        <Typography component="label" variant="subtitle1" fontWeight="bold">
                          Antecedentes Policiales
                        </Typography>
                      </Box>
                      <Input id="policeRecord" name="policeRecord" placeholder="Antecedentes Policiales" type="file" />
                    </div>
                  </div>
                  <div className="mt-3">
                    <button type="submit" className="btn btn-block btn-primary btn-lg font-weight-medium auth-form-btn">
                      Registrarte ahora
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default Register
