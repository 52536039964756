/* eslint-disable no-restricted-globals */
/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { useSearchParams, useParams } from 'react-router-dom';
import {
  TextField,
  MenuItem,
} from "@mui/material";
import moment from 'moment';
import './Users.css';
import { useState } from 'react';
import { listRequestInfoUser, statusRequestInfoUser } from '../../../api/requestinfouser.api';
import { connect } from 'react-redux';
import toastr from 'toastr';
import 'moment/locale/es-mx';

const Users = ({ token }) => {
  moment.locale('es-mx');
  let [searchParams, setSearchParams] = useSearchParams();
  const [users, setUsers] = useState([]);
  const [counterPage, setCounterPage] = useState(1);

  let { id } = useParams();
  // const [currentPage, setCurrentPage] = useState(1);
  // const [limitData, setLimitData] = useState(10);
  const currentPage = parseInt(searchParams.get('page') || 1);
  const limitData = parseInt(searchParams.get('count') || 10);
  const stateData = [
    { name: 'success', label: 'Aprobado' },
    { name: 'waiting', label: 'En espera' },
    { name: 'cancel', label: 'Rechazado' },
  ]
  const loadElements = async () => {
    try {
      const res = await listRequestInfoUser({ token, page: currentPage, count: limitData, userid: id });
      setUsers(res.data);
      setCounterPage(Math.ceil(res.paginateInfo.totalPages));
    } catch (error) {
      toastr.error('Error al obtener los datos');
    }
  }

  const seekNewData = async (value) => {
    try {
      // setCurrentPage(page);
      setSearchParams({ page: value, count: limitData });
      const res = await listRequestInfoUser({ token, page: value, count: limitData, userid: id });
      setUsers(res.data);
      setCounterPage(Math.ceil(res.paginateInfo.totalPages));
    } catch (error) {
      toastr.error('Error al obtener los datos');
    }
  }

  const stateNewLimitPage = async (limiter) => {
    try {
      setSearchParams({ page: 1, count: limiter });
      // setCurrentPage(1);
      const res = await listRequestInfoUser({ token, page: 1, count: limiter, userid: id });
      setUsers(res.data);
      setCounterPage(Math.ceil(res.paginateInfo.totalPages));
    } catch (error) {
      toastr.error('Error al obtener los datos');
    }
  }

  const formatDateMoment = (createdDate) => {
    return moment(createdDate).format('YYYY MMMM DD, h:mm:ss a')
  }

  useEffect(() => {
    loadElements();
  }, [])

  const getStatusFromValue = (element) => {
    try {
      const findStatus = stateData.find((index) => (index.name === element));
      return findStatus.label;
    } catch (error) {
      return '';
    }
  }


  return (
    <div>
      <div className="section-title-container">
        <h4>
          Solicitud de Cambio de información del usuario
        </h4>
      </div>
      <div className="card card-body">
        <div className="table-responsive">
          <table className="table table-hover">
            <thead>
              <tr>
                <th>Acciones</th>
                <th>Nombre Completo</th>
                <th>Email</th>
                <th>Teléfono</th>
                <th>Estado</th>
                <th>Creado en</th>
              </tr>
            </thead>
            <tbody>
              {users.map(element => (
                <tr>
                  <td className="text-danger">
                    {
                      element.status === 'waiting' && (
                        <>
                          <button className="btn btn-primary btn-rounded btn-sm" onClick={async () => {
                            try {
                              const value = confirm('Esta seguro de aceptar esta solicitud');
                              if (value) {
                                statusRequestInfoUser({
                                  token, _id: element._id, status: 'success'
                                });
                                window.location.reload();
                              }
                            } catch (error) {
                              if (error.info) {
                                toastr.error(error.info);
                              } else {
                                toastr.error('Error al procesar la petición');
                              }
                            }
                          }}>
                            Aceptar
                          </button>
                          <button className="btn btn-primary btn-rounded btn-sm" onClick={async () => {
                            try {
                              const value = confirm('Esta seguro de aceptar esta solicitud');
                              if (value) {
                                statusRequestInfoUser({
                                  token, _id: element._id, status: 'cancel'
                                });
                                window.location.reload();
                              }
                            } catch (error) {
                              if (error.info) {
                                toastr.error(error.info);
                              } else {
                                toastr.error('Error al procesar la petición');
                              }
                            }
                          }}>
                            Denegar
                          </button>
                        </>
                      )
                    }
                  </td>
                  <td>{element.loftyName}</td>
                  <td>{element.loftyEmail}</td>
                  <td>{element.phone}</td>
                  <td>{getStatusFromValue(element.status)}</td>
                  <td>{formatDateMoment(element.createdAt)}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
      <div style={{ display: 'flex', width: '100%', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-end' }}>
        {currentPage > 1 && (
          <button className="btn btn-primary btn-rounded btn-sm" style={{ margin: '5px' }} onClick={() => seekNewData(currentPage - 1)}>
            Previo
          </button>
        )
        }
        <div style={{ margin: '5px' }}>
          Pag: {currentPage} / {counterPage}
        </div>
        {currentPage < counterPage && (
          <button className="btn btn-primary btn-rounded btn-sm" style={{ margin: '5px' }} onClick={() => seekNewData(currentPage + 1)}>
            Siguiente
          </button>
        )}
      </div>
      <div style={{ display: 'flex', width: '100%', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-end' }}>
        <TextField defaultValue={limitData} onChange={(e) => stateNewLimitPage(e.target.value)} select name="rowsPerPage" type="rowsPerPage" label="Datos por Pagina:" variant="standard">
          <MenuItem value={10} key={10}>
            10 por Pagina
          </MenuItem>
          <MenuItem value={20} key={20}>
            20 por Pagina
          </MenuItem>
          <MenuItem value={50} key={50}>
            50 por Pagina
          </MenuItem>
        </TextField>
      </div>
    </div>
  )
}

const mapStateToProps = (state) => state.auth;

export default connect(mapStateToProps)(Users);
