import axios from '../config/Axios';

export const url = '/api/user/lofty';

export const createUser = ({ token, loftyEmail, loftyName, phone, dni, dniImage, driversLicense, appProfile, penalRecord, policeRecord, profilePicture, status, comments, }) => new Promise((resolve, reject) => {
  if (
    loftyEmail &&
    loftyName &&
    phone &&
    dni &&
    dniImage &&
    driversLicense &&
    appProfile &&
    penalRecord &&
    policeRecord &&
    profilePicture
  ) {
    const formData = new FormData();
    formData.append('loftyEmail', loftyEmail);
    formData.append('loftyName', loftyName);
    formData.append('phone', phone);
    formData.append('dni', dni);
    formData.append('dniImage', dniImage);
    formData.append('driversLicense', driversLicense);
    formData.append('appProfile', appProfile);
    formData.append('penalRecord', penalRecord);
    formData.append('policeRecord', policeRecord);
    formData.append('profilePicture', profilePicture);
    formData.append('status', status);
    formData.append('comments', comments);
    axios.post(
      `${url}/create/user/email`,
      formData,
      {
        headers: {
          AuthorizationApp: token,
          'Content-Type': 'multipart/form-data',
        }
      }
    ).then((res) => {
      const { data } = res;
      if (data.status === 'success') {
        resolve(data);
      } else {
        reject(data);
      }
    }).catch((error) => reject(error));
  } else if (!loftyEmail) {
    reject({
      status: 'error',
      info: 'Email no definido',
    });
  } else if (!penalRecord) {
    reject({
      status: 'error',
      info: 'Email no definido',
    });
  } else if (!policeRecord) {
    reject({
      status: 'error',
      info: 'Email no definido',
    });
  } else {
    reject({
      status: 'error',
      info: 'Todos los campos son requeridos',
    });
  }
});

export const createUserWebForm = ({ loftyEmail, loftyName, phone, dni, dniImage, driversLicense, appProfile, penalRecord, policeRecord, }) => new Promise((resolve, reject) => {
  if (loftyEmail && loftyName && phone && dni && dniImage && driversLicense && appProfile && penalRecord && policeRecord) {
    const formData = new FormData();
    formData.append('loftyEmail', loftyEmail);
    formData.append('loftyName', loftyName);
    formData.append('phone', phone);
    formData.append('dni', dni);
    formData.append('dniImage', dniImage);
    formData.append('driversLicense', driversLicense);
    formData.append('appProfile', appProfile);
    formData.append('penalRecord', penalRecord);
    formData.append('policeRecord', policeRecord);
    axios.post(
      `${url}/create/user/email-web`,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        }
      }
    ).then((res) => {
      const { data } = res;
      if (data.status === 'success') {
        resolve(data);
      } else {
        reject(data);
      }
    }).catch((error) => reject(error));
  } else if (!loftyEmail) {
    reject({
      status: 'error',
      info: 'Email no definido',
    });
  } else if (!penalRecord) {
    reject({
      status: 'error',
      info: 'Email no definido',
    });
  } else if (!policeRecord) {
    reject({
      status: 'error',
      info: 'Email no definido',
    });
  } else {
    reject({
      status: 'error',
      info: 'Todos los campos son requeridos',
    });
  }
});

export const updateUser = ({ token, _id, loftyEmail, loftyName, phone, dni, dniImage, driversLicense, appProfile, penalRecord, policeRecord, profilePicture, status, comments, }) => new Promise((resolve, reject) => {
  if (loftyEmail && loftyName && phone && dni) {
    const formData = new FormData();
    formData.append('loftyEmail', loftyEmail);
    formData.append('loftyName', loftyName);
    formData.append('phone', phone);
    formData.append('dni', dni);
    formData.append('dniImage', dniImage);
    formData.append('driversLicense', driversLicense);
    formData.append('appProfile', appProfile);
    formData.append('penalRecord', penalRecord);
    formData.append('policeRecord', policeRecord);
    formData.append('profilePicture', profilePicture);
    formData.append('status', status);
    formData.append('comments', comments);
    axios.put(
      `${url}/update/basic/${_id}`,
      formData,
      {
        headers: {
          AuthorizationApp: token,
          'Content-Type': 'multipart/form-data',
        }
      }
    ).then((res) => {
      const { data } = res;
      if (data.status === 'success') {
        resolve(data);
      } else {
        console.log(data);
        reject(data);
      }
    }).catch((error) => reject(error));
  } else if (!loftyEmail) {
    reject({
      status: 'error',
      info: 'Email no definido',
    });
  } else if (!loftyName) {
    reject({
      status: 'error',
      info: 'Nombre no definido',
    });
  } else {
    reject({
      status: 'error',
      info: 'Todos los campos son requeridos',
    });
  }
});

export const loginWithEmail = ({ loftyEmail, loftyPassword }) => new Promise((resolve, reject) => {
  if (loftyEmail && loftyPassword) {
    axios.post(`${url}/login/email`, {
      loftyEmail, loftyPassword,
    }).then((res) => {
      const { data } = res;
      if (data.status === 'success') {
        resolve(data);
      } else {
        reject(data);
      }
    }).catch((error) => reject(error));
  } else if (!loftyEmail) {
    reject({
      status: 'error',
      info: 'Email no definido',
    });
  } else if (!loftyPassword) {
    reject({
      status: 'error',
      info: 'Contraseña no definido',
    });
  }
});

export const getLoggedUser = ({ token }) => new Promise((resolve, reject) => {
  if (token) {
    axios.get(`${url}/one`, {
      headers: {
        AuthorizationApp: token,
      },
    }).then((res) => {
      const { data } = res;
      if (data.status === 'success') {
        resolve(data);
      } else {
        reject(data);
      }
    }).catch((error) => reject(error));
  } else {
    reject({
      status: 'error',
      info: 'Token no definido',
    });
  }
});

export const logout = ({ token }) => new Promise((resolve, reject) => {
  if (token) {
    axios.get(`${url}/logout`, {
      headers: {
        AuthorizationApp: token,
      },
    }).then((res) => {
      const { data } = res;
      if (data.status === 'success') {
        resolve(data);
      } else {
        reject(data);
      }
    }).catch((error) => reject(error));
  } else {
    reject({
      status: 'error',
      info: 'Token no definido',
    });
  }
});

export const listUsers = ({ token, page, count }) => new Promise((resolve, reject) => {
  if (token) {
    axios.get(`${url}/list`, {
      headers: {
        AuthorizationApp: token,
      },
      params: {
        page,
        count,
      },
    }).then((res) => {
      const { data } = res;
      if (data.status === 'success') {
        resolve(data);
      } else {
        reject(data);
      }
    }).catch((error) => reject(error));
  } else {
    reject({
      status: 'error',
      info: 'Token no definido',
    });
  }
});

export const totalConductor = ({ token }) => new Promise((resolve, reject) => {
  if (token) {
    axios.get(`${url}/metrics/total`, {
      headers: {
        AuthorizationApp: token,
      }
    }).then((res) => {
      if (res.data.status === 'success') {
        resolve(res.data);
      } else {
        reject(res.data);
      }
    }).catch((error) => {
      reject(error?.response?.data);
    })
  } else {
    reject({
      status: 'error',
      info: 'Token no definido'
    });
  }
});


export const getOneUser = ({
  token, _id
}) => new Promise(((resolve, reject) => {
  if (token && _id) {
    axios.get(`${url}/one/${_id}`, {
      headers: {
        AuthorizationApp: token
      }
    }).then((res) => {
      if (res.data.status === 'success') {
        resolve(res.data);
      } else {
        reject(res.data);
      }
    }).catch((error) => {
      reject(error?.response?.data);
    });
  } else if (!token) {
    reject({
      status: 'error',
      info: 'Token no definido'
    });
  } else {
    reject({
      status: 'sucess',
      info: 'Identificador no definido'
    });
  }
}));
