import React, { Component } from 'react';
import {
  Box,
  Typography,
  Input,
} from "@mui/material";
import { addRequestInfoUserController } from '../../../api/requestinfouser.api';

export class FormRequestInfoUser extends Component {
  render() {
    return (
      <div>
        <div className="d-flex align-items-center auth px-0">
          <div className="row w-100 mx-0">
            <div className="col-lg-4 mx-auto">
              <div className="auth-form-light text-left py-5 px-4 px-sm-5">
                <h2 className="text-center">¿Quiéres cambiar tu información? LLena el formulario</h2>
                <div className="brand-logo" style={{display: 'flex', justifyContent: 'center'}}>
                  <img src={'https://dicipgo.com/assets/img/logo/logo.png'} alt="logo" />
                </div>
                <form
                  id="element-form"
                  className="mt-4"
                  onSubmit={async (e) => {
                    try {
                      e.preventDefault();
                      const newElement = {
                        loftyEmail: e.target.email.value,
                        loftyName: e.target.loftyName.value,
                        phone: e.target.phone.value,
                      };
                      await addRequestInfoUserController(newElement);
                      alert('Se ha registrado de manera exitosa')
                    } catch (error) {
                      alert('Error al registrase, si el error persiste contacte a info@dicipgo.com');
                    }
                  }}
                >
                  <div className="">
                    <div className="mb-4">
                      <Box mb={1} ml={0.5} width={"100%"}>
                        <Typography component="label" variant="subtitle1" fontWeight="bold">
                          Nombre Completo
                        </Typography>
                      </Box>
                      <Input className="full-width" id="loftyName" name="loftyName" placeholder="Nombre Completo"
                        inputProps={{
                          maxLength: 500,
                          step: "1"
                        }}
                      />
                    </div>
                    <div className="mb-4">
                      <Box mb={1} ml={0.5} width={"100%"}>
                        <Typography component="label" variant="subtitle1" fontWeight="bold">
                          Email
                        </Typography>
                      </Box>
                      <Input className="full-width" id="email" name="email" placeholder="Email"
                        type="email"
                        inputProps={{
                          maxLength: 500,
                          step: "1"
                        }}
                      />
                    </div>
                    <div className="mb-4">
                      <Box mb={1} ml={0.5} width={"100%"}>
                        <Typography component="label" variant="subtitle1" fontWeight="bold">
                          Teléfono
                        </Typography>
                      </Box>
                      <Input className="full-width" id="phone" name="phone" placeholder="Teléfono"
                        inputProps={{
                          maxLength: 50,
                          step: "1"
                        }}
                      />
                    </div>
                  </div>
                  <div className="mt-3">
                    <button type="submit" className="btn btn-block btn-primary btn-lg font-weight-medium auth-form-btn">
                      Enviar Solicitud
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default FormRequestInfoUser;
