import React from 'react';
import { Dropdown } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { Trans } from 'react-i18next';
import { logout } from '../../api/user.api';
import { useNavigate } from 'react-router-dom/dist';
import { deletetoken } from '../../actions/auth.actions';
import { connect } from 'react-redux';

const Navbar = ({ token, deletetokenApp }) => {

  const navigate = useNavigate();

  const toggleOffcanvas = () => {
    document.querySelector('.sidebar-offcanvas').classList.toggle('active');
  }
  const toggleRightSidebar = () => {
    document.querySelector('.right-sidebar').classList.toggle('open');
  }

  const handleLogout = async () => {
    try {
      await logout({ token });
      deletetokenApp();
      navigate('/login');
    } catch (error) {
      console.log(error);
    }
  }
  return (
    <nav className="navbar default-layout-navbar col-lg-12 col-12 p-0 fixed-top d-flex flex-row">
      <div className="text-center navbar-brand-wrapper d-flex align-items-center justify-content-center">
        <Link className="navbar-brand brand-logo" to="/"><img src={'/logo_dicipgo.png'} alt="logo" style={{width: 'auto !important', objectFit: 'contain'}} /></Link>
        <Link className="navbar-brand brand-logo-mini d-inline-block d-lg-none" to="/"><img src={'/logo_dicipgo.png'} style={{width: 'auto !important', objectFit: 'contain'}} alt="logo" /></Link>
        <button className="navbar-toggler navbar-toggler align-self-center" type="button" onClick={() => document.body.classList.toggle('sidebar-icon-only')}>
          <span className="icon-menu"></span>
        </button>
      </div>
      <div className="navbar-menu-wrapper d-flex align-items-center">
        <h5 className="mb-0 font-weight-medium d-none d-xl-flex">
          <Trans>Bienvenido al Administrador de DICIP GO</Trans>
        </h5>
        <button className="navbar-toggler navbar-toggler-right d-lg-none align-self-center" type="button" onClick={toggleOffcanvas}>
          <span className="icon-menu"></span>
        </button>
      </div>
    </nav>
  );
}

const mapStateToProps = (state) => state.auth;

const mapDispatchToProps = (dispatch) => ({
  deletetokenApp: () => dispatch(deletetoken()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Navbar);

