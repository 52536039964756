/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Box,
  Typography,
  Input,
  TextField,
  MenuItem,
  Autocomplete,
} from "@mui/material";
import moment from 'moment';
import './Tablets.css';
import { useState } from 'react';
import { connect } from 'react-redux';
import toastr from 'toastr';
import { createTablet, listTablets, updateTablet } from '../../../api/tablet.api';
import 'moment/locale/es-mx';
import { listUsers } from '../../../api/user.api';

const AddOrEditModal = ({
  showAddModal,
  setShowAddModal,
  handleSubmit,
  isEdit,
  elementEdit,
  userData,
  selectedUser,
  setUserId
}) => (
  <Dialog open={showAddModal} onClose={() => setShowAddModal(false)} fullWidth maxWidth="lg">
    <DialogTitle>{isEdit ? 'Editar' : 'Nueva'} Tablet</DialogTitle>
    <DialogContent>
      {isEdit && elementEdit?.type === "install" && (
        <Box mb={3}>
          <Typography component="label" variant="subtitle1" fontWeight="bold" color="#0195FE">
            TIPO INSTALACIÓN
          </Typography>
        </Box>
      )}
      <form id="element-form" onSubmit={(e) => handleSubmit(e)}>
        <div className="form-main-container">
          <div className="form-input-container">
            <Box mb={1} ml={0.5} width={"100%"}>
              <Typography component="label" variant="subtitle1" fontWeight="bold">
                IMEI
              </Typography>
            </Box>
            <Input className="full-width" id="imei" name="imei" placeholder="AABBBBBBCCCCCCD" defaultValue={elementEdit.imei}
              type="text"
              inputProps={{
                maxLength: 15,
                step: "1"
              }}
            />
          </div>
          <div className="form-input-container">
            <Box mb={1} ml={0.5} width={"100%"}>
              <Typography component="label" variant="subtitle1" fontWeight="bold">
                Marca
              </Typography>
            </Box>
            <Input className="full-width" id="tabletbrand" name="tabletbrand" placeholder="Samsung" defaultValue={elementEdit.tabletbrand}
              inputProps={{
                maxLength: 100,
                step: "1"
              }}
            />
          </div>
          <div className="form-input-container">
            <Box mb={1} ml={0.5} width={"100%"}>
              <Typography component="label" variant="subtitle1" fontWeight="bold">
                Modelo
              </Typography>
            </Box>
            <Input className="full-width" id="tabletmodel" name="tabletmodel" placeholder="Galaxy Tab..." defaultValue={elementEdit.tabletmodel}
              type="text"
              inputProps={{
                maxLength: 100,
                step: "1"
              }}
            />
          </div>
          <div className="form-input-container">
            <Box mb={1} ml={0.5} width={"100%"}>
              <Typography component="label" variant="subtitle1" fontWeight="bold">
                Almacenamiento en GiB
              </Typography>
            </Box>
            <Input className="full-width" id="gbstorage" name="gbstorage" placeholder="128, 256..." defaultValue={elementEdit.gbstorage}
              type="number"
              inputProps={{
                maxLength: 13,
                step: "1"
              }}
            />
          </div>
          <div className="form-input-container">
            <Box mb={1} ml={0.5} width={"100%"}>
              <Typography component="label" variant="subtitle1" fontWeight="bold">
                Memoria RAM en GiB
              </Typography>
            </Box>
            <Input className="full-width" id="gibram" name="gibram" placeholder="8, 12..." defaultValue={elementEdit.gibram}
              type="number"
              inputProps={{
                maxLength: 13,
                step: "1"
              }}
            />
          </div>
          <div className="form-input-container">
            <Box mb={1} ml={0.5} width={"100%"}>
              <Typography component="label" variant="subtitle1" fontWeight="bold">
                Estado Físico (del 1 al 10)
              </Typography>
            </Box>
            <Input className="full-width" id="state10" name="state10" placeholder="" defaultValue={elementEdit.state10}
              type="number"
              inputProps={{
                maxLength: 2,
                step: "1",
                min: 1,
                max: 10,
              }}
            />
          </div>
          <div className="form-input-container">
            <Box mb={1} ml={0.5} width={"100%"}>
              <Typography component="label" variant="subtitle1" fontWeight="bold">
                Detalles del estado físico
              </Typography>
            </Box>
            <Input className="full-width" id="statedetails" name="statedetails" placeholder="Esquina abollada, cámara rota, etc..." defaultValue={elementEdit.statedetails} />
          </div>
          <div className="form-input-container">
            <Box mb={1} ml={0.5} width={"100%"}>
              <Typography component="label" variant="subtitle1" fontWeight="bold">
                Teléfono
              </Typography>
            </Box>
            <Input className="full-width" id="telephone" name="telephone" placeholder="Teléfono" defaultValue={elementEdit.telephone}
              inputProps={{
                maxLength: 50,
                step: "1"
              }}
            />
          </div>
          {isEdit && elementEdit?.type === 'install' && (
            <div className="form-input-container">
              <Box mb={1} ml={0.5} width={"100%"}>
                <Typography component="label" variant="subtitle1" fontWeight="bold">
                  Estado
                </Typography>
              </Box>
              <TextField className="full-width" defaultValue={elementEdit.status} select name="status" type="status" label="Estado:" variant="standard">
                <MenuItem value={'approved'}>Aprobado</MenuItem>
                <MenuItem value={'locked'}>Bloqueado</MenuItem>
              </TextField>
            </div>
          )}
          <div className="form-input-container">
            <Box mb={1} ml={0.5} width={"100%"}>
              <Typography component="label" variant="subtitle1" fontWeight="bold">
                Usuario
              </Typography>
            </Box>
            <Autocomplete
              id="userId"
              getOptionLabel={(option) => (`${option.dni} - ${option.loftyName}`)}
              isOptionEqualToValue={(option, value) => (option._id === value)}
              options={userData}
              renderInput={(params) => <TextField {...params} />}
              defaultValue={selectedUser}
              onChange={(e, value) => {
                if (value?._id) {
                  setUserId(value._id);
                }
              }}
              renderOption={(props, option) => {
                return (
                  <li {...props}>
                    {option.dni} {option.loftyName}
                  </li>
                )
              }}
            />
          </div>
        </div>
      </form>
    </DialogContent>
    <DialogActions style={{ paddingRight: '1rem', paddingBottom: '1rem' }}>
      <button className="btn btn-primary" form="element-form" type="submit">
        <Typography variant="button" fontWeight="bold" textTransform="capitalize" color="white">
          {isEdit ? 'EDITAR' : 'AGREGAR'}
        </Typography>
      </button>
    </DialogActions>
  </Dialog>
)

const Tablets = ({ token }) => {
  moment.locale('es-mx');
  let [searchParams, setSearchParams] = useSearchParams();
  const [showAddModal, setShowAddModal] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [elementEdit, setElementEdit] = useState(false);
  const [users, setUsers] = useState([]);
  const [counterPage, setCounterPage] = useState(1);
  // const [currentPage, setCurrentPage] = useState(1);
  // const [limitData, setLimitData] = useState(10);
  const currentPage = parseInt(searchParams.get('page'));
  const limitData = parseInt(searchParams.get('count'));
  const [userId, setUserId] = useState();
  const [infoUser, setInfoUser] = useState([]);

  const getUserInfo = (_id) => {
    try {
      const userInfoReq = infoUser.find((index) => (index._id === _id));
      return userInfoReq;
    } catch (error) {
      return null;
    }
  }

  const getUserName = (_id) => {
    try {
      const findUser = infoUser.find((index) => (index._id === _id));
      return findUser.loftyName;
    } catch (error) {
      return null;
    }
  }

  const loadElements = async () => {
    try {
      const res = await listTablets({ token, page: currentPage, count: limitData });
      setUsers(res.data);
      setCounterPage(Math.ceil(res.paginateInfo.totalPages));
    } catch (error) {
      toastr.error('Error al obtener los datos');
    }
  }


  const loadUsers = async () => {
    try {
      const resUserdata = await listUsers({ token, page: 1, count: 0 });
      setInfoUser(resUserdata.data);
    } catch (error) {
      console.log(error)
      toastr.error('Error al obtener los datos');
    }
  }

  const seekNewData = async (value) => {
    try {
      // setCurrentPage(page);
      setSearchParams({ page: value, count: limitData });
      const res = await listTablets({ token, page: value, count: limitData });
      setUsers(res.data);
      setCounterPage(Math.ceil(res.paginateInfo.totalPages));
    } catch (error) {
      toastr.error('Error al obtener los datos');
    }
  }

  const stateNewLimitPage = async (limiter) => {
    try {
      setSearchParams({ page: 1, count: limiter });
      // setCurrentPage(1);
      const res = await listTablets({ token, page: 1, count: limiter });
      setUsers(res.data);
      setCounterPage(Math.ceil(res.paginateInfo.totalPages));
    } catch (error) {
      toastr.error('Error al obtener los datos');
    }
  }


  const handleSubmit = async (e) => {
    e.preventDefault();
    const newElement = {
      userId,
      imei: e.target.imei.value,
      tabletmodel: e.target.tabletmodel.value,
      tabletbrand: e.target.tabletbrand.value,
      gbstorage: e.target.gbstorage.value,
      gibram: e.target.gibram.value,
      state10: e.target.state10.value,
      statedetails: e.target.statedetails.value,
      status: elementEdit.type === 'register' ? undefined : e.target.status.value,
      telephone: e.target.telephone.value,
    };
    try {
      let response;
      if (isEdit) {
        response = await updateTablet({ token, ...newElement, _id: elementEdit._id });
        setShowAddModal(false);
      } else {
        response = await createTablet({ token, ...newElement });
      }
      toastr.success(response?.info);
      loadElements();
      document.querySelector('#element-form').reset();
    } catch (error) {
      console.log(error)
      toastr.error(error?.info || error?.response?.data?.info);
    }
  }

  const showAdd = () => {
    setShowAddModal(true);
    setIsEdit(false);
    setElementEdit({});
  }

  const showEdit = (element) => {
    setShowAddModal(true);
    setIsEdit(true);
    setElementEdit(element);
  }

  const formatDateMoment = (createdDate) => {
    return moment(createdDate).format('YYYY MMMM DD, h:mm:ss a')
  }

  const translateState = (state) => {
    if (state === 'approved') {
      return 'Aprobado';
    }
    return 'Bloqueado';
  }

  useEffect(() => {
    loadElements();
    loadUsers();
  }, [])

  return (
    <div>
      <div className="section-title-container">
        <h4>
          Tablets
        </h4>
        <button className="btn btn-primary" onClick={showAdd}>
          Agregar
        </button>
      </div>
      <div className="card card-body">
        <div className="table-responsive">
          <table className="table table-hover">
            <thead>
              <tr>
                <th>Acciones</th>
                <th>ID</th>
                <th>IMEI</th>
                <th>Modelo</th>
                <th>Marca</th>
                <th>Almacenamiento</th>
                <th>RAM</th>
                <th>Estado</th>
                <th>Detalles</th>
                <th>Estado Tablet</th>
                <th>Telefono</th>
                <th>Usuario</th>
                <th>Creado en</th>
              </tr>
            </thead>
            <tbody>
              {users.map(element => (
                <tr>
                  <td className="text-danger">
                    <button className="btn btn-primary btn-rounded btn-sm" onClick={() => showEdit(element)}>
                      Editar
                    </button>
                  </td>
                  <td>{element._id}</td>
                  <td>{element.imei}</td>
                  <td>{element.tabletmodel}</td>
                  <td>{element.tabletbrand}</td>
                  <td>{element.gbstorage}</td>
                  <td>{element.gibram}</td>
                  <td>{element.state10}</td>
                  <td>{element.statedetails}</td>
                  <td>{translateState(element.status)}</td>
                  <td>{element.telephone}</td>
                  <td>{getUserName(element.userId)}</td>
                  <td>{formatDateMoment(element.loftyCreatedAt)}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
      <div style={{ display: 'flex', width: '100%', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-end' }}>
        {currentPage > 1 && (
          <button className="btn btn-primary btn-rounded btn-sm" style={{ margin: '5px' }} onClick={() => seekNewData(currentPage - 1)}>
            Previo
          </button>
        )
        }
        <div style={{ margin: '5px' }}>
          Pag: {currentPage} / {counterPage}
        </div>
        {currentPage < counterPage && (
          <button className="btn btn-primary btn-rounded btn-sm" style={{ margin: '5px' }} onClick={() => seekNewData(currentPage + 1)}>
            Siguiente
          </button>
        )}
      </div>
      <div style={{ display: 'flex', width: '100%', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-end' }}>
        <TextField defaultValue={limitData} onChange={(e) => stateNewLimitPage(e.target.value)} select name="rowsPerPage" type="rowsPerPage" label="Datos por Pagina:" variant="standard">
          <MenuItem value={10} key={10}>
            10 por Pagina
          </MenuItem>
          <MenuItem value={20} key={20}>
            20 por Pagina
          </MenuItem>
          <MenuItem value={50} key={50}>
            50 por Pagina
          </MenuItem>
        </TextField>
      </div>
      <AddOrEditModal
        showAddModal={showAddModal}
        setShowAddModal={setShowAddModal}
        handleSubmit={handleSubmit}
        isEdit={isEdit}
        elementEdit={elementEdit}
        userData={infoUser}
        setUserId={setUserId}
        selectedUser={getUserInfo(elementEdit.userId)}
      />
    </div>
  )
}

const mapStateToProps = (state) => state.auth;

export default connect(mapStateToProps)(Tablets);
