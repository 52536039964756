import React, {useEffect} from "react";
import { useParams } from 'react-router-dom';
import { viewContactQRPublic } from '../../../api/contactqr.api';

const QrPage = () => {
  const { id } = useParams();
  useEffect(() => {
    const handleData = async () => {
      const doc = await viewContactQRPublic({ _id: id });
      window.location.href = doc.data.url;
    }
    handleData();
  }, [id]);
  return (
    <div
      style={{
        height: '100vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <div>
        <h2>
          Cargando ...
        </h2>
      </div>
    </div>
  )

}

export default QrPage;
