/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import ChartistGraph, { FixedScaleAxis } from 'react-chartist';
import DateRangePicker from '@wojtekmaj/react-daterange-picker';
import moment from 'moment';
import './Companies.css';
import { useState } from 'react';
import { connect } from 'react-redux';
import toastr from 'toastr';
import { useParams } from 'react-router-dom';
import { getOneCompany } from '../../../api/company.api';
import { totalCompanyAdvertisign } from '../../../api/advertising.api';
import { totalCompanyCampaign } from '../../../api/campaign.api';
import { totalMetricsLogCampaign, metricsVisutalizationVideo } from '../../../api/logcampaign.api';
import 'moment/locale/es-mx';

function createData(time, amount) {
  return { time, amount };
}

const OneCompanies = ({ token }) => {
  moment.locale('es-mx');
  const [data, setData] = useState({});
  const [initialDate, setInitialDate] = useState(new Date('2024-01-02'));
  const [endDate, setEndDate] = useState(new Date());
  const [totalAdvertising, setTotalAdvertising] = useState(0);
  const [totalCampaign, setTotalCampaign] = useState(0);
  const [totalLogsCampaign, setTotalLogsCampaign] = useState(0);
  const [incomeExpenseSummaryChartData, setIncomenExpenseSummary] = useState({
    // A labels array that can contain any sort of values
    labels: ['En', 'Feb', 'Mar', 'Abr', 'May', 'Jun', 'Jul', 'Ago', 'Sep', 'Oct', 'Nov', 'Dic'],
    // Our series array that contains series objects or in this case series data arrays
    series: [
      [505, 781, 480, 985, 410, 822, 388, 874, 350, 642, 320, 796]
    ]
  });
  let { id } = useParams();

  const loadElements = async () => {
    try {
      const res = await getOneCompany({ token, _id: id });
      setData(res.data);
      const resAdvertising = await totalCompanyAdvertisign({
        token, endDate, initialDate, idCompany: res.data._id
      });
      setTotalAdvertising(resAdvertising.data);
      const rsCampaign = await totalCompanyCampaign({
        token, endDate, idCompany: res.data._id, initialDate,
      });
      setTotalCampaign(rsCampaign.data);
      const resLogCampaign = await totalMetricsLogCampaign({
        idCompany: res.data._id, type: 'company', queryFirstEndInitalDate: endDate,
        queryFirstInitialDate: initialDate, querySecondEndDate: endDate,
        querySecondInitialDate: initialDate, token,
      });
      setTotalLogsCampaign(resLogCampaign.data.data1);
    } catch (error) {
      console.log(error);
      toastr.error('Error al obtener los datos');
    }
  }

  const handleDataLogCampaign = async () => {
    try {
      const res = await metricsVisutalizationVideo({
        token,
        queryFirstInitialDate: initialDate,
        queryFirstEndInitalDate: endDate,
        querySecondEndDate: endDate,
        querySecondInitialDate: endDate,
        type: 'company',
        idCompany: id,
      });
      var groupedData = []
      var counter = 0;
      console.log(res);
      res.data.data1.forEach(element => {
        var dateData = element.createdAt.split('T')[0];
        var year = dateData.split('-')[0]
        var month = dateData.split('-')[1]
        var joint = year + '-'+month

        var temp = groupedData[counter]

        if(temp){
          if(temp.time === joint){
            groupedData[counter].amount++;
          }else{
            groupedData.push(createData(joint, 1))
            counter++;
          }
        }
        else{
          groupedData.push(createData(joint, 1))
        }
      });
      // mapeando todos los dias
      var labelTemp = [];
      var dataTemp = [];
      for (let index = 0; index < groupedData.length; index++) {
        labelTemp.push(groupedData[index].time);
        dataTemp.push(groupedData[index].amount);
      }
      setIncomenExpenseSummary({
        // A labels array that can contain any sort of values
        labels: labelTemp,
        // Our series array that contains series objects or in this case series data arrays
        series: [
          dataTemp
        ]
      });
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    loadElements();
    handleDataLogCampaign();
  }, [initialDate, endDate]);

  const incomeExpenseSummaryChartOptions = {
    height: 300,
    axisY: {
      high: 1000,
      low: 250,
      referenceValue: 1000,
      type: FixedScaleAxis,
      ticks: [250, 500, 750, 1000]
    },
    showArea: true,
    showPoint: false,
    fullWidth: true
  }

  const incomeExpenseSummaryChartResponsiveOptions = [
    ['screen and (max-width: 480px)', {
      height: 150,
      axisX: {
        labelInterpolationFnc: function (value) {
          return value;
        }
      }
    }]
  ];

  return (
    <div>
      <div className="row">
        <div className="col-md-12 grid-margin">
          <div className="card">
            <div className="card-body">
              <div className="row">
                <div className="col-md-12">
                  <div className="d-sm-flex align-items-baseline report-summary-header">
                    <h5 className="font-weight-semibold">
                      <b>Empresa: </b> {` ${data.name}`}
                    </h5>
                  </div>
                </div>
                <div className="col-md-12">
                  <DateRangePicker
                      className="ml-auto income-expense-summary-chart-daterange"
                      calendarIcon={<i className='icon-arrow-down'></i>}
                      format="MMMM d, y"
                      clearIcon={null}
                      value={[initialDate, endDate]}
                      onChange={date => {
                        setInitialDate(date[0]);
                        setEndDate(date[1])
                      }}
                    />
                </div>
              </div>
              <div className="row report-inner-cards-wrapper">
                <div className=" col-md -6 col-xl report-inner-card">
                  <div className="inner-card-text">
                    <span className="report-title">Cantidad de Publicidad</span>
                    <h4>
                      {totalAdvertising.toLocaleString('en')}
                    </h4>
                  </div>
                  <div className="inner-card-icon bg-success">
                    <span className="material-symbols-outlined">
                      store
                      </span>
                  </div>
                </div>
                <div className="col-md-6 col-xl report-inner-card">
                  <div className="inner-card-text">
                    <span className="report-title">Cantidad de videos</span>
                    <h4>
                      {totalCampaign.toLocaleString('en')}
                    </h4>
                  </div>
                  <div className="inner-card-icon bg-danger">
                    <span className="material-symbols-outlined">
                      person
                    </span>
                  </div>
                </div>
                <div className="col-md-6 col-xl report-inner-card">
                  <div className="inner-card-text">
                    <span className="report-title">Visualizaciones de Videos</span>
                    <h4>
                      {totalLogsCampaign.toLocaleString('en')}
                    </h4>
                  </div>
                  <div className="inner-card-icon bg-primary">
                    <span class="material-symbols-outlined">
                      visibility
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-md-12 grid-margin">
          <div className="card">
            <div className="card-body">
              <div className="row income-expense-summary-chart-text">
                <div className="col-xl-4">
                  <h5>Historial de Visualización de Videos</h5>
                  <p className="small text-muted">
                    Es para que puedan comparar en meses la cantidad de visualizaciones de camapañas en general que tiene
                    la aplicación Dicip Go  
                  </p>
                </div>
                <div className=" col-md-3 col-xl-2">
                  
                </div>
                <div className="col-md-3 col-xl-2">
                </div>
                <div className="col-md-6 col-xl-4 d-flex align-items-center">
                </div>
              </div>
              <div className="row income-expense-summary-chart mt-3">
                <div className="col-md-12">
                  <div className="ct-chart" id="income-expense-summary-chart"></div>
                  <ChartistGraph
                    type="Line"
                    data={incomeExpenseSummaryChartData}
                    options={incomeExpenseSummaryChartOptions}
                    responsive-options={incomeExpenseSummaryChartResponsiveOptions}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = (state) => state.auth;

export default connect(mapStateToProps)(OneCompanies);
