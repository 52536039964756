import { ADD_TOKEN, ADD_USER, DELETE_TOKEN } from '../types/auth.types';

const initialState = {
  token: localStorage.getItem('token'),
  user: undefined,
  userRole: localStorage.getItem('userRole'),
};

const reducerAuth = (state = initialState, { type, payload }) => {
  switch (type) {
    case ADD_TOKEN:
      return {
        ...state,
        token: payload?.token,
        user: payload?.user,
        userRole: payload?.user?.loftyRole,
      };
    case DELETE_TOKEN:
      return {
        ...state,
        token: undefined,
        user: undefined,
        userRole: undefined,
      };
    case ADD_USER:
      return {
        ...state,
        user: payload?.user,
        userRole: payload?.user?.loftyRole,
      };
    default:
      return state;
  }
};

export default reducerAuth;
