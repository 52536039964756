import React from 'react';
import { useNavigate } from "react-router-dom";
import { Form } from 'react-bootstrap';
import { useParams } from 'react-router-dom';

import {connect} from 'react-redux';

import toastr from 'toastr';
import { resetPassword } from '../../api/reset.api';

const ResetPassword = ({token}) => {

  const navigate = useNavigate();
  const {idtoken} = useParams();
  if (token) {
    navigate('/dashboard');
  }

  const handleRedirectToInit = () => {
    navigate('/login');
  }

  const handleResetPassword = async (e) => {
    e.preventDefault();
    try {
      await resetPassword({
        password: e.target.newPassword.value,
        confirmPassword: e.target.confirmPassword.value,
        token: idtoken,
      });
      toastr.success('Se reseteo la contraseña de manera exitosa.');
      navigate('/login');
    } catch (error) {
      toastr.error(error?.response?.data?.info || 'Error, verifique que la contraseña este correcta.');
      console.log(error);
    }
  }

  return (
    <div>
      <div className="d-flex align-items-center auth px-0">
        <div className="row w-100 mx-0">
          <div className="col-lg-4 mx-auto">
            <div className="auth-form-light text-left py-5 px-4 px-sm-5">
              <div className="brand-logo">
                <img src={'https://dicipgo.com/assets/img/logo/logo.png'} alt="logo" />
              </div>
              <h4>Bienvenido al Administrador de:</h4>
              <h6 className="font-weight-light">DICIP GO ©</h6>
              <Form onSubmit={handleResetPassword} className="pt-3">
                <div style={{padding: '10px'}}>
                  Ingresa tu nueva contraseña
                </div>
                <Form.Group className="d-flex search-field">
                  <Form.Control name="newPassword" type="password" placeholder="Password" size="lg" className="h-auto" />
                </Form.Group>
                <Form.Group className="d-flex search-field">
                  <Form.Control name="confirmPassword" type="password" placeholder="Confirm Password" size="lg" className="h-auto" />
                </Form.Group>
                <div className="mt-3">
                  <button type="submit" className="btn btn-block btn-primary btn-lg font-weight-medium auth-form-btn">
                    Cambiar Contraseña
                  </button>
                </div>
                <div className="my-2 d-flex justify-content-between align-items-center">
                  <div className="form-check">
                  </div>
                  <a href="/login" onClick={handleRedirectToInit} className="auth-link text-black">
                    Regresar a Inicio
                  </a>
                </div>
              </Form>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = (state) => state.auth;

export default connect(mapStateToProps)(ResetPassword);

