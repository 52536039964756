/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Box,
  Typography,
  Input,
  TextField,
  MenuItem,
} from "@mui/material";
import Select from '@mui/material/Select';
import moment from 'moment';
import { useState } from 'react';
import { connect } from 'react-redux';
import toastr from 'toastr';
import 'moment/locale/es-mx';
import { createPlans, listPlans, updatePlans } from '../../../api/plans.api';

let center = {
  lat: 14.0839154,
  lng: -87.2049727
};

const AddOrEditModal = ({
  showAddModal,
  onClose,
  handleSubmit,
  isEdit,
  elementEdit,
}) => {
  console.log(elementEdit);
  const [selectedCurrency, setSelectedCurrency] = useState(elementEdit?.currency);

  return (
    <Dialog open={showAddModal} onClose={onClose} fullWidth maxWidth="lg">
      <DialogTitle>{isEdit ? 'Editar' : 'Nuevo'} Plan</DialogTitle>
      <DialogContent>
        <form id="element-form" onSubmit={(e) => handleSubmit(e)}>
          <div className="form-main-container">
            <div className="form-input-container">
              <Box mb={1} ml={0.5} width={"100%"}>
                <Typography component="label" variant="subtitle1" fontWeight="bold">
                  Etiqueta
                </Typography>
              </Box>
              <Input
                inputProps={{ maxLength: 200 }}
                className="full-width"
                id="label"
                name="label"
                placeholder="Plan Medium"
                defaultValue={elementEdit.label}
              />
            </div>
            <div className="form-input-container">
              <Box mb={1} ml={0.5} width={"100%"}>
                <Typography component="label" variant="subtitle1" fontWeight="bold">
                  Slug
                </Typography>
              </Box>
              <Input
                inputProps={{ maxLength: 200 }}
                className="full-width"
                id="slug"
                name="slug"
                placeholder="medio"
                defaultValue={elementEdit.slug}
              />
            </div>
            <div className="form-input-container">
              <Box mb={1} ml={0.5} width={"100%"}>
                <Typography component="label" variant="subtitle1" fontWeight="bold">
                  Moneda
                </Typography>
              </Box>
              <Select
                value={selectedCurrency}
                onChange={(e) => {
                  setSelectedCurrency(e.target.value)
                }}
                name='currency'
                className='full-width'
              >
                <MenuItem value="USD">
                  Dolares
                </MenuItem>
              </Select>
            </div>
            <div className="form-input-container">
              <Box mb={1} ml={0.5} width={"100%"}>
                <Typography component="label" variant="subtitle1" fontWeight="bold">
                  Precio
                </Typography>
              </Box>
              <Input
                type="number"
                inputProps={{ maxLength: 100, step: "1", min: 20 }}
                className="full-width"
                id="price"
                name="price"
                placeholder="20, 40, 120..."
                defaultValue={elementEdit.price}
              />
            </div>
            <div className="form-input-container">
              <Box mb={1} ml={0.5} width={"100%"}>
                <Typography component="label" variant="subtitle1" fontWeight="bold">
                  Número de anuncios
                </Typography>
              </Box>
              <Input
                type="number"
                inputProps={{ maxLength: 100, step: "1", min: 1 }}
                className="full-width"
                id="numberAdvertising"
                name="numberAdvertising"
                placeholder="20, 40, 120..."
                defaultValue={elementEdit.numberAdvertising}
              />
            </div>
            <div className="form-input-container">
              <Box mb={1} ml={0.5} width={"100%"}>
                <Typography component="label" variant="subtitle1" fontWeight="bold">
                  Número de minimo de segundos
                </Typography>
              </Box>
              <Input
                type="number"
                inputProps={{ maxLength: 100, step: "1", min: 1 }}
                className="full-width"
                id="minSeconds"
                name="minSeconds"
                placeholder="20, 40, 120..."
                defaultValue={elementEdit.minSeconds}
              />
            </div>
            <div className="form-input-container">
              <Box mb={1} ml={0.5} width={"100%"}>
                <Typography component="label" variant="subtitle1" fontWeight="bold">
                  Número de máximo de segundos
                </Typography>
              </Box>
              <Input
                type="number"
                inputProps={{ maxLength: 100, step: "1", min: 1 }}
                className="full-width"
                id="maxSeconds"
                name="maxSeconds"
                placeholder="20, 40, 120..."
                defaultValue={elementEdit.maxSeconds}
              />
            </div>
            <div className="form-input-container">
              <Box mb={1} ml={0.5} width={"100%"}>
                <Typography component="label" variant="subtitle1" fontWeight="bold">
                  Número de cambios
                </Typography>
              </Box>
              <Input
                type="number"
                inputProps={{ maxLength: 100, step: "1", min: 1 }}
                className="full-width"
                id="maxSeconds"
                name="maxSeconds"
                placeholder="20, 40, 120..."
                defaultValue={elementEdit.changeNumber}
              />
            </div>
          </div>
        </form>
      </DialogContent>
      <DialogActions style={{ paddingRight: '1rem', paddingBottom: '1rem' }}>
        <button className="btn btn-primary" form="element-form" type="submit">
          <Typography variant="button" fontWeight="bold" textTransform="capitalize" color="white">
            {isEdit ? 'EDITAR' : 'AGREGAR'}
          </Typography>
        </button>
      </DialogActions>
    </Dialog>
  )
}

const Plan = ({ token }) => {
  moment.locale('es-mx');
  let [searchParams, setSearchParams] = useSearchParams();
  const [showAddModal, setShowAddModal] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [elementEdit, setElementEdit] = useState(false);
  const [campaigns, setCampaigns] = useState([]);
  const [centerReact, setCenterReact] = useState(center);
  const [counterPage, setCounterPage] = useState(1);
  const currentPage = parseInt(searchParams.get('page'));
  const limitData = parseInt(searchParams.get('count'));

  const loadElements = async () => {
    try {
      const res = await listPlans({ token, page: currentPage, count: limitData });
      setCampaigns(res.data);
      setCounterPage(Math.ceil(res?.paginateInfo?.totalPages));
    } catch (error) {
      console.log(error);
      toastr.error('Error al obtener los datos');
    }
  }

  const seekNewData = async (value) => {
    try {
      // setCurrentPage(page);
      setSearchParams({ page: value, count: limitData });
      const res = await listPlans({ token, page: value, count: limitData });
      setCampaigns(res.data);
      setCounterPage(Math.ceil(res?.paginateInfo?.totalPages));
    } catch (error) {
      toastr.error('Error al obtener los datos');
    }
  }

  const stateNewLimitPage = async (limiter) => {
    try {
      setSearchParams({ page: 1, count: limiter });
      // setCurrentPage(1);
      const res = await listPlans({ token, page: 1, count: limiter });
      setCampaigns(res.data);
      setCounterPage(Math.ceil(res?.paginateInfo?.totalPages));
    } catch (error) {
      toastr.error('Error al obtener los datos');
    }
  }


  const handleSubmit = async (e) => {
    e.preventDefault();
    const newElement = {
      slug: e.target.slug.value,
      label: e.target.label.value,
      price: e.target.price.value,
      currency: e.target.currency.value,
      numberAdvertising: e.target.numberAdvertising.value,
      minSeconds: e.target.minSeconds.value,
      maxSeconds: e.target.maxSeconds.value,
      changeNumber: e.target.changeNumber.value
    };
    try {
      let response;
      if (isEdit) {
        response = await updatePlans({ token, ...newElement, _id: elementEdit._id });
        setShowAddModal(false);
      } else {
        response = await createPlans({ token, ...newElement });
      }
      toastr.success(response?.info);
      loadElements();
      document.querySelector('#element-form').reset();
    } catch (error) {
      console.log(error)
      toastr.error(error?.info || error?.response?.data?.info);
    }
  }

  const showAdd = () => {
    setShowAddModal(true);
    setIsEdit(false);
    setElementEdit({});
  }

  const showEdit = (element) => {
    setShowAddModal(true);
    setIsEdit(true);
    setElementEdit(element);
    const newCenter = {
      lat: element.latitude || center.lat,
      lng: element.longitude || center.lng,
    }
    center = newCenter;
    setCenterReact(newCenter);
  }

  useEffect(() => {
    loadElements();
  }, [])

  const formatDateMoment = (createdDate) => {
    return moment(createdDate).format('YYYY MMMM DD, h:mm:ss a')
  }

  const onCloseStateEdit = () => {
    setElementEdit(false);
    setIsEdit(false);
    setShowAddModal(false);
  }

  return (
    <div>
      <div className="section-title-container">
        <h4>
          Campañas de Publicidad
        </h4>
        <button className="btn btn-primary" onClick={showAdd}>
          Agregar
        </button>
      </div>
      <div className="card card-body">
        <div className="table-responsive">
          <table className="table table-hover">
            <thead>
              <tr>
                <th>Acciones</th>
                <th>Etiqueta</th>
                <th>slug</th>
                <th>Moneda</th>
                <th>Precio</th>
                <th>Minimo de segundos</th>
                <th>Máximo de segundos</th>
                <th>Cantidad de anuncios</th>
                <th>Número de cambios</th>
                <th>Fecha de creación</th>
              </tr>
            </thead>
            <tbody>
              {campaigns.map(element => (
                <tr>
                  <td className="text-danger">
                    <button className="btn btn-primary btn-rounded btn-sm" onClick={() => showEdit(element)}>
                      Editar
                    </button>
                  </td>
                  <td>{element.label}</td>
                  <td>{element.slug}</td>
                  <td>{element.currency}</td>
                  <td>{element.price}</td>
                  <td>{element.minSeconds}</td>
                  <td>{element.maxSeconds}</td>
                  <td>{element.numberAdvertising}</td>
                  <td>{element.changeNumber}</td>
                  <td>{formatDateMoment(element.createdAt)}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
      {/* <div style={{ display: 'flex', width: '100%', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-end' }}>
        {currentPage > 1 && (
          <button className="btn btn-primary btn-rounded btn-sm" style={{ margin: '5px' }} onClick={() => seekNewData(currentPage - 1)}>
            Previo
          </button>
        )
        }
        <div style={{ margin: '5px' }}>
          Pag: {currentPage} / {counterPage}
        </div>
        {currentPage < counterPage && (
          <button className="btn btn-primary btn-rounded btn-sm" style={{ margin: '5px' }} onClick={() => seekNewData(currentPage + 1)}>
            Siguiente
          </button>
        )}
      </div>
      <div style={{ display: 'flex', width: '100%', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-end' }}>
        <TextField defaultValue={limitData} onChange={(e) => stateNewLimitPage(e.target.value)} select name="rowsPerPage" type="rowsPerPage" label="Datos por Pagina:" variant="standard">
          <MenuItem value={10} key={10}>
            10 por Pagina
          </MenuItem>
          <MenuItem value={20} key={20}>
            20 por Pagina
          </MenuItem>
          <MenuItem value={50} key={50}>
            50 por Pagina
          </MenuItem>
        </TextField>
      </div> */}
      <AddOrEditModal
        showAddModal={showAddModal}
        // setShowAddModal={setShowAddModal}
        onClose={() => onCloseStateEdit()}
        handleSubmit={handleSubmit}
        isEdit={isEdit}
        elementEdit={elementEdit}
        centerReact={centerReact}
        setCenterReact={setCenterReact}
      />
    </div>
  )
}

const mapStateToProps = (state) => state.auth;

export default connect(mapStateToProps)(Plan);
