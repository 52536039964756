/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import {useSearchParams} from 'react-router-dom';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Box,
  Typography,
  Input,
  TextField,
  MenuItem,
  Autocomplete,
} from "@mui/material";
import moment from 'moment';
import './Vehicles.css';
import { useState } from 'react';
import { connect } from 'react-redux';
import toastr from 'toastr';
import { createVehicle, getVehicules, updateVehicule } from '../../../api/vechiles.api';
import { listUsers } from '../../../api/user.api';
import 'moment/locale/es-mx';

const AddOrEditModal = ({
  showAddModal,
  setShowAddModal,
  handleSubmit,
  isEdit,
  elementEdit,
  userData,
  setUserid,
  selectedUser,
}) => (
  <Dialog open={showAddModal} onClose={() => setShowAddModal(false)} fullWidth maxWidth="lg">
    <DialogTitle>{isEdit ? 'Editar' : 'Nuevo'} Unidad de Transporte</DialogTitle>
    <DialogContent>
      <form id="element-form" onSubmit={(e) => handleSubmit(e)}>
        <div className="form-main-container">
          <div className="form-input-container">
            <Box mb={1} ml={0.5} width={"100%"}>
              <Typography component="label" variant="subtitle1" fontWeight="bold">
                Número de Placa
              </Typography>
            </Box>
            <Input className="full-width" id="platenumber" name="platenumber" placeholder="PCD1214" defaultValue={elementEdit.platenumber}
              inputProps={{
                maxLength: 15,
              }}
            />
          </div>
          <div className="form-input-container">
            <Box mb={1} ml={0.5} width={"100%"}>
              <Typography component="label" variant="subtitle1" fontWeight="bold">
                Modelo
              </Typography>
            </Box>
            <Input className="full-width" id="modelname" name="modelname" placeholder="Honda CRV ..." defaultValue={elementEdit.modelname}
              inputProps={{
                maxLength: 100,
              }}
            />
          </div>
          <div className="form-input-container">
            <Box mb={1} ml={0.5} width={"100%"}>
              <Typography component="label" variant="subtitle1" fontWeight="bold">
                Color
              </Typography>
            </Box>
            <Input className="full-width" id="color" name="color" placeholder="Blanco" defaultValue={elementEdit.color}
              inputProps={{
                maxLength: 100,
                step: "1"
              }}
            />
          </div>
          <div className="form-input-container">
            <Box mb={1} ml={0.5} width={"100%"}>
              <Typography component="label" variant="subtitle1" fontWeight="bold">
                Año
              </Typography>
            </Box>
            <Input className="full-width" id="year" name="year" placeholder="2015" defaultValue={elementEdit.year}
              type="number"
              inputProps={{
                maxLength: 13,
                step: "1"
              }}
            />
          </div>
          <div className="form-input-container">
            <Box mb={1} ml={0.5} width={"100%"}>
              <Typography component="label" variant="subtitle1" fontWeight="bold">
                Usuario
              </Typography>
            </Box>
            <Autocomplete
              id="userId"
              getOptionLabel={(option) => (`${option.dni} - ${option.loftyName}`)}
              isOptionEqualToValue={(option, value) => (option._id === value)}
              options={userData}
              renderInput={(params) => <TextField {...params} />}
              defaultValue={selectedUser}
              onChange={(e, value) => {
                if (value?._id) {
                  setUserid(value._id);
                }
              }}
              renderOption={(props, option) => {
                return (
                  <li {...props}>
                    {option.dni} {option.loftyName}
                  </li>
                )
              }}
            />
          </div>
        </div>
      </form>
    </DialogContent>
    <DialogActions style={{ paddingRight: '1rem', paddingBottom: '1rem' }}>
      <button className="btn btn-primary" form="element-form" type="submit">
        <Typography variant="button" fontWeight="bold" textTransform="capitalize" color="white">
          {isEdit ? 'EDITAR' : 'AGREGAR'}
        </Typography>
      </button>
    </DialogActions>
  </Dialog>
)

const Vehicules = ({ token }) => {
  moment.locale('es-mx');
  let [searchParams, setSearchParams] = useSearchParams();
  const [showAddModal, setShowAddModal] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [elementEdit, setElementEdit] = useState(false);
  const [users, setUsers] = useState([]);
  const [counterPage, setCounterPage] = useState(1);
  // const [currentPage, setCurrentPage] = useState(1);
  // const [limitData, setLimitData] = useState(10);
  const currentPage = parseInt(searchParams.get('page'));
  const limitData = parseInt(searchParams.get('count'));
  const [infoUser, setInfoUser] = useState([]);
  const [userId, setUserid] = useState();

  const loadElements = async () => {
    try {
      const resUserdata = await listUsers({ token, page: 1, count: 0 });
      const res = await getVehicules({ token, page: currentPage, count: limitData });
      setUsers(res.data);
      setInfoUser(resUserdata.data);
      setCounterPage(Math.ceil(res.paginateInfo.totalPages));
    } catch (error) {
      console.log(error)
      toastr.error('Error al obtener los datos');
    }
  }

  const seekNewData = async (value) => {
    try {
      // setCurrentPage(page);
      setSearchParams({page: value, count: limitData});
      const res = await getVehicules({ token, page: value, count: limitData });
      setUsers(res.data);
      setCounterPage(Math.ceil(res.paginateInfo.totalPages));
    } catch (error) {
      toastr.error('Error al obtener los datos');
    }
  }

  const stateNewLimitPage = async (limiter) => {
    try {
      setSearchParams({page: 1, count: limiter});
      // setCurrentPage(1);
      const res = await getVehicules({ token, page: 1, count: limiter });
      setUsers(res.data);
      setCounterPage(Math.ceil(res.paginateInfo.totalPages));
    } catch (error) {
      toastr.error('Error al obtener los datos');
    }
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    const newElement = {
      platenumber: e.target.platenumber.value,
      color: e.target.color.value,
      modelname: e.target.modelname.value,
      year: e.target.year.value,
      userId: userId,
    };
    try {
      let response;
      if (isEdit) {
        response = await updateVehicule({ token, ...newElement, _id: elementEdit._id });
        setShowAddModal(false);
      } else {
        response = await createVehicle({ token, ...newElement });
      }
      toastr.success(response?.info);
      loadElements();
      document.querySelector('#element-form').reset();
    } catch (error) {
      console.log(error)
      toastr.error(error?.info || error?.response?.data?.info);
    }
  }

  const showAdd = () => {
    setShowAddModal(true);
    setIsEdit(false);
    setElementEdit({});
  }

  const showEdit = (element) => {
    setShowAddModal(true);
    setIsEdit(true);
    setElementEdit(element);
  }

  useEffect(() => {
    loadElements();
  }, [])

  const getUserName = (_id) => {
    try {
      const findUser = infoUser.find((index) => (index._id === _id));
      return findUser.loftyName;
    } catch (error) {
      return null;
    }
  }

  const getUserInfo = (_id) => {
    try {
      const userInfoReq = infoUser.find((index) => (index._id === _id));
      return userInfoReq;
    } catch (error) {
      return null;
    }
  }

  const formatDateMoment = (createdDate) => {
    return moment(createdDate).format('YYYY MMMM DD, h:mm:ss a')
  }

  return (
    <div>
      <div className="section-title-container">
        <h4>
          Unidad de transporte
        </h4>
        <button className="btn btn-primary" onClick={showAdd}>
          Agregar
        </button>
      </div>
      <div className="card card-body">
        <div className="table-responsive">
          <table className="table table-hover">
            <thead>
              <tr>
                <th>Acciones</th>
                <th>Número de Placa</th>
                <th>Color</th>
                <th>Modelo</th>
                <th>Año</th>
                <th>Usuario</th>
                <th>Creado en</th>
              </tr>
            </thead>
            <tbody>
              {users.map(element => (
                <tr>
                  <td className="text-danger">
                    <button className="btn btn-primary btn-rounded btn-sm" onClick={() => showEdit(element)}>
                      Editar
                    </button>
                  </td>
                  <td>{element.platenumber}</td>
                  <td>{element.color}</td>
                  <td>{element.modelname}</td>
                  <td>{element.year}</td>
                  <td>{getUserName(element.userId)}</td>
                  <td>{formatDateMoment(element.loftyCreatedAt)}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
      <div style={{ display: 'flex', width: '100%', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-end' }}>
        {currentPage > 1 && (
          <button className="btn btn-primary btn-rounded btn-sm" style={{ margin: '5px' }} onClick={() => seekNewData(currentPage - 1)}>
            Previo
          </button>
        )
        }
        <div style={{ margin: '5px' }}>
          Pag: {currentPage} / {counterPage}
        </div>
        {currentPage < counterPage && (
          <button className="btn btn-primary btn-rounded btn-sm" style={{ margin: '5px' }} onClick={() => seekNewData(currentPage + 1)}>
            Siguiente
          </button>
        )}
      </div>
      <div style={{ display: 'flex', width: '100%', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-end' }}>
        <TextField defaultValue={limitData} onChange={(e) => stateNewLimitPage(e.target.value)} select name="rowsPerPage" type="rowsPerPage" label="Datos por Pagina:" variant="standard">
          <MenuItem value={10} key={10}>
            10 por Pagina
          </MenuItem>
          <MenuItem value={20} key={20}>
            20 por Pagina
          </MenuItem>
          <MenuItem value={50} key={50}>
            50 por Pagina
          </MenuItem>
        </TextField>
      </div>
      <AddOrEditModal
        showAddModal={showAddModal}
        setShowAddModal={setShowAddModal}
        handleSubmit={handleSubmit}
        isEdit={isEdit}
        elementEdit={elementEdit}
        userData={infoUser}
        setUserid={setUserid}
        selectedUser={getUserInfo(elementEdit.userId)}
      />
    </div>
  )
}

const mapStateToProps = (state) => state.auth;

export default connect(mapStateToProps)(Vehicules);
