import React, { useEffect } from 'react';
import {useSearchParams} from 'react-router-dom';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Box,
  Typography,
  MenuItem,
  TextField,
  Autocomplete,
} from "@mui/material";
import moment from 'moment';
import { useState } from 'react';
import { connect } from 'react-redux';
import toastr from 'toastr';
import {createTabletVehicle, getAllTabletVehicle, updateTabletVehicle} from '../../../api/tabletxvehicle.api';
import {getVehicules} from '../../../api/vechiles.api';
import {listTablets} from '../../../api/tablet.api';
import 'moment/locale/es-mx';

const AddOrEditModal = ({
  showAddModal,
  setShowAddModal,
  handleSubmit,
  isEdit,
  elementEdit,
  vehicleData,
  tabletData,
  setTabletID,
  setVehicleID,
  selectedVehicle,
  selectedTablet,
}) => (
  <Dialog open={showAddModal} onClose={() => setShowAddModal(false)} fullWidth maxWidth="lg">
    <DialogTitle>{isEdit ? 'Editar' : 'Nuevo'} Tablet por Vehiculo</DialogTitle>
    <DialogContent>
      <form id="element-form" onSubmit={(e) => handleSubmit(e)}>
        <div className="form-main-container">
          <div className="form-input-container">
            <Box mb={1} ml={0.5} width={"100%"}>
              <Typography component="label" variant="subtitle1" fontWeight="bold">
                Tablet
              </Typography>
            </Box>
            <Autocomplete
              disablePortal
              id="tablet"
              getOptionLabel={(option) => (option.imei || selectedTablet)}
              isOptionEqualToValue={(option, value) => (option._id === value)}
              options={tabletData}
              renderInput={(params) => <TextField {...params} />}
              // value={selectedTablet}
              defaultValue={selectedTablet}
              onChange={(e, value) => {
                // setSelectedType(value);
                if (value?._id) {
                  setTabletID(value._id);
                }
              }}
              renderOption={(props, option) => {
                return (
                  <li {...props}>
                    {option.imei} {option.tabletbrand} {option.tabletmodel}
                  </li>
                )
              }}
            />
            {/* <TextField defaultValue={elementEdit.tablet} select fullWidth name="tablet" type="tablet" label="Tablet:" variant="standard">
              {
                tabletData.map(({ imei, tabletmodel, tabletbrand, _id }) => (
                  <MenuItem value={_id} key={_id}>
                    {`${imei} ${tabletmodel} ${tabletbrand}`}
                  </MenuItem>
                ))
              }
            </TextField> */}
          </div>
          <div className="form-input-container">
            <Box mb={1} ml={0.5} width={"100%"}>
              <Typography component="label" variant="subtitle1" fontWeight="bold">
                Vehiculo
              </Typography>
            </Box>
            <Autocomplete
              disablePortal
              id="vehicle"
              getOptionLabel={(option) => (option.platenumber || selectedVehicle)}
              isOptionEqualToValue={(option, value) => (option._id === value)}
              options={vehicleData}
              renderInput={(params) => <TextField {...params} />}
              // value={selectedVehicle}
              defaultValue={selectedVehicle}
              onChange={(e, value) => {
                // setSelectedType(value);
                if (value?._id) {
                  setVehicleID(value._id);
                }
              }}
              renderOption={(props, option) => {
                return (
                  <li {...props}>
                    {option.platenumber} {option.modelname}
                  </li>
                )
              }}
            />
            {/* <TextField defaultValue={elementEdit.vehicle} select fullWidth name="vehicle" type="vehicle" label="Vehiculo:" variant="standard">
              {
                vehicleData.map(({ platenumber, modelname, _id }) => (
                  <MenuItem value={_id} key={_id}>
                    {`${modelname} ${platenumber}`}
                  </MenuItem>
                ))
              }
            </TextField> */}
          </div>
        </div>
      </form>
    </DialogContent>
    <DialogActions style={{ paddingRight: '1rem', paddingBottom: '1rem' }}>
      <button className="btn btn-primary" form="element-form" type="submit">
        <Typography variant="button" fontWeight="bold" textTransform="capitalize" color="white">
          {isEdit ? 'EDITAR' : 'AGREGAR'}
        </Typography>
      </button>
    </DialogActions>
  </Dialog>
)

const TabletsPerVehicle = ({ token }) => {
  moment.locale('es-mx');
  let [searchParams, setSearchParams] = useSearchParams();
  const [showAddModal, setShowAddModal] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [elementEdit, setElementEdit] = useState(false);
  const [tablets, setTablets] = useState([]);
  const [vehicles, setVehicles] = useState([]);
  const [tabletVehicleData, setTabletVehicleData] = useState([]);
  const [counterPage, setCounterPage] = useState(1);
  // const [currentPage, setCurrentPage] = useState(1);
  // const [limitData, setLimitData] = useState(10);
  const currentPage = parseInt(searchParams.get('page'));
  const limitData = parseInt(searchParams.get('count'));
  const [tabletID, setTabletID] = useState();
  const [vehicleID, setVehicleID] = useState();

  const loadElements = async () => {
    try {
      const resTablets = await listTablets({token, page: 1, count: 0});
      const resVehicles = await getVehicules({token, page: 1, count: 0});
      const resTabletVehicles = await getAllTabletVehicle({ token, page: currentPage, count: limitData });
      setTablets(resTablets.data);
      setVehicles(resVehicles.data);
      setTabletVehicleData(resTabletVehicles.data);
      setCounterPage(Math.ceil(resTabletVehicles.paginateInfo.totalPages));
    } catch (error) {
      toastr.error('Error al obtener los datos');
    }
  }

  const seekNewData = async (value) => {
    try {
      // setCurrentPage(page);
      setSearchParams({page: value, count: limitData});
      const res = await getAllTabletVehicle({ token, page: currentPage, count: limitData });
      setTabletVehicleData(res.data);
      setCounterPage(Math.ceil(res.paginateInfo.totalPages));
    } catch (error) {
      toastr.error('Error al obtener los datos');
    }
  }

  const stateNewLimitPage = async (limiter) => {
    try {
      setSearchParams({page: 1, count: limiter});
      // setCurrentPage(1);
      const res = await getAllTabletVehicle({ token, page: 1, count: limiter });
      setTabletVehicleData(res.data);
      setCounterPage(Math.ceil(res.paginateInfo.totalPages));
    } catch (error) {
      toastr.error('Error al obtener los datos');
    }
  }

  const getVehicleInfo = (_id) => {
    try {
      const findVehicle = vehicles.find((index) => (index._id === _id));
      return findVehicle.platenumber;
    } catch (error) {
      return null;
    }
  }
  
  const getTabletInfo = (_id) => {
    try {
      const findTablet = tablets.find((index) => (index._id === _id));
      return findTablet.imei;
    } catch (error) {
      return null;
    }
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    const newElement = {
      tablet: tabletID,
      vehicle: vehicleID,
    };
    try {
      let response;
      if (isEdit) {
        response = await updateTabletVehicle({ token, ...newElement, _id: elementEdit._id });
        setShowAddModal(false);
      } else {
        response = await createTabletVehicle({ token, ...newElement });
      }
      toastr.success(response?.info);
      loadElements();
      document.querySelector('#element-form').reset();
    } catch (error) {
      console.log(error)
      toastr.error(error?.info || error?.response?.data?.info);
    }
  }

  const showAdd = () => {
    setShowAddModal(true);
    setIsEdit(false);
    setElementEdit({});
  }

  const showEdit = (element) => {
    setVehicleID(element.vehicle);
    setTabletID(element.tablet);
    setShowAddModal(true);
    setIsEdit(true);
    setElementEdit(element);
  }

  useEffect(() => {
    loadElements();
  }, [])

  const formatDateMoment = (createdDate) => {
    return moment(createdDate).format('YYYY MMMM DD, h:mm:ss a')
  }

  return (
    <div>
      <div className="section-title-container">
        <h4>
          Tablet por Vehiculo
        </h4>
        <button className="btn btn-primary" onClick={showAdd}>
          Agregar
        </button>
      </div>
      <div className="card card-body">
        <div className="table-responsive">
          <table className="table table-hover">
            <thead>
              <tr>
                <th>Tablet</th>
                <th>Vehiculo</th>
                <th>Creado en</th>
                <th>Acciones</th>
              </tr>
            </thead>
            <tbody>
              {tabletVehicleData.map(index => (
                <tr>
                  <td>{getTabletInfo(index.tablet)}</td>
                  <td>{getVehicleInfo(index.vehicle)}</td>
                  <td>{formatDateMoment(index.loftyCreatedAt)}</td>
                  <td className="text-danger">
                    <button className="btn btn-primary btn-rounded btn-sm" onClick={() => showEdit(index)}>
                      Editar
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
      <div style={{ display: 'flex', width: '100%', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-end' }}>
        {currentPage > 1 && (
          <button className="btn btn-primary btn-rounded btn-sm" style={{ margin: '5px' }} onClick={() => seekNewData(currentPage - 1)}>
            Previo
          </button>
        )
        }
        <div style={{ margin: '5px' }}>
          Pag: {currentPage} / {counterPage}
        </div>
        {currentPage < counterPage && (
          <button className="btn btn-primary btn-rounded btn-sm" style={{ margin: '5px' }} onClick={() => seekNewData(currentPage + 1)}>
            Siguiente
          </button>
        )}
      </div>
      <div style={{ display: 'flex', width: '100%', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-end' }}>
        <TextField defaultValue={limitData} onChange={(e) => stateNewLimitPage(e.target.value)} select name="rowsPerPage" type="rowsPerPage" label="Datos por Pagina:" variant="standard">
          <MenuItem value={10} key={10}>
            10 por Pagina
          </MenuItem>
          <MenuItem value={20} key={20}>
            20 por Pagina
          </MenuItem>
          <MenuItem value={50} key={50}>
            50 por Pagina
          </MenuItem>
        </TextField>
      </div>
      <AddOrEditModal
        showAddModal={showAddModal}
        setShowAddModal={setShowAddModal}
        handleSubmit={handleSubmit}
        isEdit={isEdit}
        elementEdit={elementEdit}
        tabletData={tablets}
        vehicleData={vehicles}
        setVehicleID={setVehicleID}
        setTabletID={setTabletID}
        selectedVehicle={getVehicleInfo(elementEdit.vehicle)}
        selectedTablet={getTabletInfo(elementEdit.tablet)}
      />
    </div>
  )
}

const mapStateToProps = (state) => state.auth;

export default connect(mapStateToProps)(TabletsPerVehicle);
