import axios from '../config/Axios';

const url = '/api/requestinfouser';

export const addRequestInfoUserController = ({
  loftyName, phone, loftyEmail
}) => new Promise((resolve, reject) => {
  if (loftyEmail && loftyName && phone) {
    axios.post(`${url}/add`, {
      loftyName, phone, loftyEmail
    }).then((res) => {
      if (res.data.status === 'success') {
        resolve(res.data);
      } else {
        reject(res.data);
      }
    }).catch((error) => {
      reject(error?.response?.data);
    });
  } else if (!loftyEmail) {
    reject({
      status: 'error',
      info: 'Correo no definido',
    });
  } else if (!loftyName) {
    reject({
      status: 'error',
      info: 'Nombre completo no definido'
    });
  } else {
    reject({
      status: 'error',
      info: 'Telefono no definido'
    });
  }
});

export const statusRequestInfoUser = ({
  token, _id, status
}) => new Promise((resolve, reject) => {
  if (token && _id && status) {
    axios.put(`${url}/status/${_id}`, {
      status
    }, {
      headers: {
        AuthorizationApp: token,
      }
    }).then((res) => {
      if (res.data.status === 'success') {
        resolve(res.data);
      } else {
        reject(res.data);
      }
    }).catch((error) => {
      reject(error?.response?.data);
    });
  } else if (!_id) {
    reject({
      status: 'error',
      info: 'Identificador no definido'
    });
  } else if (!status) {
    reject({
      status: 'error',
      info: 'Estatus no definido'
    });
  } else {
    reject({
      status: 'error',
      info: 'Token no definido'
    });
  }
});

export const listRequestInfoUser = ({
  token, userid, page, count
}) => new Promise((resolve, reject) => {
  if (token && userid) {
    axios.get(`${url}/byuser/${userid}`, {
      headers: {
        AuthorizationApp: token
      },
      params: {
        page, count,
      }
    }).then((res) => {
      if (res.data.status === 'success') {
        resolve(res.data);
      } else {
        reject(res.data);
      }
    }).catch((error) => {
      reject(error?.response?.data);
    });
  } else if (!userid) {
    reject({
      status: 'error',
      info: 'Usuario no definido'
    });
  } else {
    reject({
      status: 'error',
      info: 'Token no definido'
    });
  }
});
