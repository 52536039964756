import axios from '../config/Axios';

const url = '/api/contactcompany';

export const addContactCompany = ({
  fullname, email, telephone,
  position, comment, token, idCompany,
}) => new Promise((resolve, reject) => {
  if (fullname && email && telephone && position && token) {
    axios.post(`${url}/add`, {
      fullname, email, telephone,
      position, comment, idCompany,
    }, {
      headers: {
        AuthorizationApp: token,
      }
    }).then((res) => {
      if (res.data.status === 'success') {
        resolve(res.data);
      } else {
        reject(res.data);
      }
    }).catch((error) => {
      reject(error?.response?.data);
    });
  } else if (!fullname) {
    reject({
      status: 'error',
      info: 'Nombre completo es requerido'
    });
  } else if (!email) {
    reject({
      status: 'error',
      info: 'Correo no definido'
    });
  } else if (!telephone) {
    reject({
      status: 'error',
      info: 'Telefono no definido'
    });
  } else if (!position) {
    reject({
      status: 'error',
      info: 'Cargo no definido'
    });
  }
});

export const updateContactCompany = ({
  fullname, email, telephone,
  position, comment, token, _id,
}) => new Promise((resolve, reject) => {
  if (fullname && email && telephone && position && _id && token) {
    axios.put(`${url}/update/${_id}`, {
      fullname, email, telephone,
      position, comment,
    }, {
      headers: {
        AuthorizationApp: token,
      }
    }).then((res) => {
      if (res.data.status === 'success') {
        resolve(res.data);
      } else {
        reject(res.data);
      }
    }).catch((error) => {
      reject(error?.response?.data);
    });
  } else if (!fullname) {
    reject({
      status: 'error',
      info: 'Nombre completo es requerido'
    });
  } else if (!email) {
    reject({
      status: 'error',
      info: 'Correo no definido'
    });
  } else if (!telephone) {
    reject({
      status: 'error',
      info: 'Telefono no definido'
    });
  } else if (!position) {
    reject({
      status: 'error',
      info: 'Cargo no definido'
    });
  }
});

export const getAllContactCompany = ({
  token,  page, count, idCompany
}) => new Promise((resolve, reject) => {
  if (token) {
    axios.get(`${url}/all`, {
      params: {
        page, count, idCompany
      },
      headers: {
        AuthorizationApp: token,
      }
    }).then((res) => {
      if (res.data.status === 'success') {
        resolve(res.data);
      } else {
        reject(res.data);
      }
    }).catch((error) => {
      reject(error?.response?.data);
    });
  } else {
    reject({
      status: 'error',
      info: 'Token no definido'
    });
  }
}) 
