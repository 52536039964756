import {ADD_TOKEN, ADD_USER, DELETE_TOKEN} from '../types/auth.types';

export const addToken = ({token, user}) => {
  localStorage.setItem('token', token);
  localStorage.setItem('userRole', user.loftyRole);
  return {
    type: ADD_TOKEN,
    payload: {
      token, user, userRole: user.loftyRole
    },
  };
};

export const deletetoken = () => {
  localStorage.removeItem('token');
  localStorage.removeItem('userRole');
  return {
    type: DELETE_TOKEN,
  };
};

export const addUser = (user) => ({
  type: ADD_USER,
  payload: {user},
});
