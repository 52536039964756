import React from 'react';
import { useNavigate } from "react-router-dom";
import { Form } from 'react-bootstrap';

import {connect} from 'react-redux';

import toastr from 'toastr';
import { createResetToken } from '../../api/reset.api';

const ForgotPassword = ({token}) => {

  const navigate = useNavigate();
  console.log(token);
  // if (token) {
  //   navigate('/dashboard');
  // }

  const handleResetPassword = async (e) => {
    e.preventDefault();
    try {
      await createResetToken({
        email: e.target.loftyEmail.value,
      });
      toastr.success('Se ha enviado el correo para resetear contraseña');
    } catch (error) {
      toastr.error(error?.response?.data?.info || 'Error, verifique su correo.');
      console.log(error);
    }
  }

  const handleRedirectToInit = () => {
    navigate('/login');
  }

  return (
    <div>
      <div className="d-flex align-items-center auth px-0">
        <div className="row w-100 mx-0">
          <div className="col-lg-4 mx-auto">
            <div className="auth-form-light text-left py-5 px-4 px-sm-5">
              <div className="brand-logo">
                <img src={'https://dicipgo.com/assets/img/logo/logo.png'} alt="logo" />
              </div>
              <h4>Bienvenido al Administrador de:</h4>
              <h6 className="font-weight-light">DICIP GO ©</h6>
              <Form onSubmit={handleResetPassword} className="pt-3">
                <Form.Group className="d-flex search-field">
                  <Form.Control name="loftyEmail"  type="email" placeholder="Email" size="lg" className="h-auto" />
                </Form.Group>
                <div>
                  Ingresa tu email y enviaremos
                  a tu correo un link para
                  resetear tu contraseña
                </div>
                <div className="mt-3">
                  <button type="submit" className="btn btn-block btn-primary btn-lg font-weight-medium auth-form-btn">
                    Resetear Contraseña
                  </button>
                </div>
                <div className="my-2 d-flex justify-content-between align-items-center">
                  <div className="form-check">
                  </div>
                  <a href="/login" onClick={handleRedirectToInit} className="auth-link text-black">
                    Regresar a Inicio
                  </a>
                </div>
              </Form>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = (state) => state.auth;

export default connect(mapStateToProps)(ForgotPassword);

