import axios from '../config/Axios';

const url = '/api/vehicle';

export const createVehicle = ({
  platenumber, color, modelname,
  year, userid, token
}) => new Promise((resolve, reject) => {
  if (!platenumber) {
    return reject({
      status: 'error',
      info: 'Número de placa es requerida'
    });
  }
  if (!color) {
    return reject({
      status: 'error',
      info: 'Color del vehiculo es requerido'
    });
  }
  if (!modelname) {
    return reject({
      status: 'error',
      info: 'Modelo del vehiculo es requerido'
    });
  }
  if (!year) {
    return reject({
      status: 'error',
      info: 'Año del vehiculo es requerido'
    });
  }
  if (!token) {
    return reject({
      status: 'error',
      info: 'Token es requerido'
    });
  }
  if (!userid) {
    return reject({
      status: 'error',
      info: 'Usuario no definido'
    });
  }
  axios.post(`${url}/add`, {
    platenumber, color, modelname,
    year, userid,
  }, {
    headers: {
      AuthorizationApp: token,
    },
  }).then((res) => {
    if (res.data.status === 'success') {
      resolve(res.data);
    } else {
      reject(res.data);
    }
  }).catch((error) => {
    reject(error.response?.data);
  });
});

export const updateVehicule = ({
  platenumber, color, modelname,
  year, userid, _id, token,
}) => new Promise((resolve, reject) => {
  if (!platenumber) {
    return reject({
      status: 'error',
      info: 'Número de placa es requerida'
    });
  }
  if (!color) {
    return reject({
      status: 'error',
      info: 'Color del vehiculo es requerido'
    });
  }
  if (!modelname) {
    return reject({
      status: 'error',
      info: 'Modelo del vehiculo es requerido'
    });
  }
  if (!year) {
    return reject({
      status: 'error',
      info: 'Año del vehiculo es requerido'
    });
  }
  if (!token) {
    return reject({
      status: 'error',
      info: 'Token es requerido'
    });
  }
  if (!_id) {
    return reject({
      status: 'error',
      info: 'Identificador no definido'
    });
  }
  if (!userid) {
    return reject({
      status: 'error',
      info: 'Usuario no definido'
    });
  }
  axios.put(`${url}/update/${_id}`, {
    platenumber, color, modelname,
    year, userid,
  }, {
    headers: {
      AuthorizationApp: token,
    },
  }).then((res) => {
    if (res.data.status === 'success') {
      resolve(res.data);
    } else {
      reject(res.data);
    }
  }).catch((error) => {
    reject(error.response?.data);
  });
});

export const getVehicules = ({
  token,  page, count,
}) => new Promise((resolve, reject) => {
  if (!token) {
    return reject({
      status: 'error',
      info: 'Token no definido'
    });
  }
  axios.get(`${url}/list`, {
    headers: {
      AuthorizationApp: token,
    },
    params: {
      page,
      count,
    },
  }).then((res) => {
    if (res.data.status === 'success') {
      resolve(res.data);
    } else {
      reject(res.data);
    }
  }).catch((error) => {
    reject(error.response?.data);
  });
});

export const getOneVehicle = ({
  token, _id
}) => new Promise((resolve, reject) => {
  if (!token) {
    return reject({
      status: 'error',
      info: 'Token no definido'
    });
  }
  if (!_id) {
    return reject({
      status: 'error',
      info: 'Identificador no definido'
    });
  }
  axios.get(`${url}/one/${_id}`, {
    headers: {
      AuthorizationApp: token,
    },
  }).then((res) => {
    if (res.data.status === 'success') {
      resolve(res.data);
    } else {
      reject(res.data);
    }
  }).catch((error) => {
    reject(error.response?.data);
  });
});

export const deleteVehicule = ({
  token, _id
}) => new Promise((resolve, reject) => {
  if (!token) {
    return reject({
      status: 'error',
      info: 'Token no definido'
    });
  }
  if (!_id) {
    return reject({
      status: 'error',
      info: 'Identificador no definido'
    });
  }
  axios.delete(`${url}/remove/${_id}`, {
    headers: {
      AuthorizationApp: token,
    },
  }).then((res) => {
    if (res.data.status === 'success') {
      resolve(res.data);
    } else {
      reject(res.data);
    }
  }).catch((error) => {
    reject(error.response?.data);
  });
});
