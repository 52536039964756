import React, { useEffect } from 'react';
import {useSearchParams} from 'react-router-dom';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Box,
  Typography,
  Input,
  MenuItem,
  TextField,
} from "@mui/material";
import moment from 'moment';
import { useState } from 'react';
import { connect } from 'react-redux';
import toastr from 'toastr';
import { createRefer, updateRefer, listRefer } from '../../../api/refer.api';
import { listUsers } from '../../../api/user.api';
import 'moment/locale/es-mx';

const AddOrEditModal = ({
  showAddModal,
  setShowAddModal,
  handleSubmit,
  isEdit,
  elementEdit,
  userData,
}) => (
  <Dialog open={showAddModal} onClose={() => setShowAddModal(false)} fullWidth maxWidth="lg">
    <DialogTitle>{isEdit ? 'Editar' : 'Nuevo'} Referidos</DialogTitle>
    <DialogContent>
      <form id="element-form" onSubmit={(e) => handleSubmit(e)}>
        <div className="form-main-container">
          <div className="form-input-container">
            <Box mb={1} ml={0.5} width={"100%"}>
              <Typography component="label" variant="subtitle1" fontWeight="bold">
                Referido
              </Typography>
            </Box>
            <TextField defaultValue={elementEdit.referredUserId} select fullWidth name="referredUserId" type="referredUserId" label="Referido:" variant="standard">
              {
                userData.map(({ loftyName, dni, _id }) => (
                  <MenuItem value={_id} key={_id}>
                    {`${loftyName} ${dni}`}
                  </MenuItem>
                ))
              }
            </TextField>
          </div>
          <div className="form-input-container">
            <Box mb={1} ml={0.5} width={"100%"}>
              <Typography component="label" variant="subtitle1" fontWeight="bold">
                Referente
              </Typography>
            </Box>
            <TextField defaultValue={elementEdit.refererId} select fullWidth name="refererId" type="refererId" label="Referente:" variant="standard">
              {
                userData.map(({ loftyName, dni, _id }) => (
                  <MenuItem value={_id} key={_id}>
                    {`${loftyName} ${dni}`}
                  </MenuItem>
                ))
              }
            </TextField>
          </div>
          {!isEdit && (
            <div className="form-input-container">
              <Box mb={1} ml={0.5} width={"100%"}>
                <Typography component="label" variant="subtitle1" fontWeight="bold">
                  Codigo de Referido
                </Typography>
              </Box>
              <Input inputProps={{ maxLength: 200 }} className="full-width" id="referralCode" name="referralCode" placeholder="xyz-999/codigo-111..." defaultValue={elementEdit.referCode} />
            </div>
          )}
          <div className="form-input-container">
            <Box mb={1} ml={0.5} width={"100%"}>
              <Typography component="label" variant="subtitle1" fontWeight="bold">
                Bono Lps.
              </Typography>
            </Box>
            <Input type="number" inputProps={{ maxLength: 90 }} className="full-width" id="bonus" name="bonus" placeholder="1, 2, 3..." defaultValue={elementEdit.bonus} />
          </div>
          <div className="form-input-container">
            <Box mb={1} ml={0.5} width={"100%"}>
              <Typography component="label" variant="subtitle1" fontWeight="bold">
                Duración (días)
              </Typography>
            </Box>
            <Input type="number" inputProps={{ maxLength: 90 }} className="full-width" id="duration" name="duration" placeholder="1, 2, 3..." defaultValue={elementEdit.duration} />
          </div>
        </div>
      </form>
    </DialogContent>
    <DialogActions style={{ paddingRight: '1rem', paddingBottom: '1rem' }}>
      <button className="btn btn-primary" form="element-form" type="submit">
        <Typography variant="button" fontWeight="bold" textTransform="capitalize" color="white">
          {isEdit ? 'EDITAR' : 'AGREGAR'}
        </Typography>
      </button>
    </DialogActions>
  </Dialog>
)

const Refers = ({ token }) => {
  moment.locale('es-mx');
  let [searchParams, setSearchParams] = useSearchParams();
  const [showAddModal, setShowAddModal] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [elementEdit, setElementEdit] = useState(false);
  const [users, setUsers] = useState([]);
  const [referrals, setReferrals] = useState([]);
  const [counterPage, setCounterPage] = useState(1);
  // const [currentPage, setCurrentPage] = useState(1);
  // const [limitData, setLimitData] = useState(10);
  const currentPage = parseInt(searchParams.get('page'));
  const limitData = parseInt(searchParams.get('count'));

  const loadElements = async () => {
    try {
      const res = await listUsers({ token, page: 1, count: 0 });
      const refRes = await listRefer({ token, page: currentPage, count: limitData });
      setUsers(res.data);
      setReferrals(refRes.data);
      setCounterPage(Math.ceil(refRes.paginateInfo.totalPages));
    } catch (error) {
      toastr.error('Error al obtener los datos');
    }
  }

  const seekNewData = async (value) => {
    try {
      // setCurrentPage(page);
      setSearchParams({page: value, count: limitData});
      const res = await listRefer({ token, page: currentPage, count: limitData });
      setReferrals(res.data);
      setCounterPage(Math.ceil(res.paginateInfo.totalPages));
    } catch (error) {
      toastr.error('Error al obtener los datos');
    }
  }

  const stateNewLimitPage = async (limiter) => {
    try {
      setSearchParams({page: 1, count: limiter});
      // setCurrentPage(1);
      const res = await listRefer({ token, page: 1, count: limiter });
      setReferrals(res.data);
      setCounterPage(Math.ceil(res.paginateInfo.totalPages));
    } catch (error) {
      toastr.error('Error al obtener los datos');
    }
  }


  const getUserName = (_id) => {
    try {
      const findUser = users.find((index) => (index._id === _id));
      return findUser.loftyName;
    } catch (error) {
      return null;
    }
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    const newElement = {
      referredUserId: e.target.referredUserId.value,
      refererId: e.target.refererId.value,
      duration: e.target.duration.value,
      bonus: e.target.bonus.value,
    };
    try {
      let response;
      if (isEdit) {
        response = await updateRefer({ token, ...newElement, _id: elementEdit._id });
        setShowAddModal(false);
      } else {
        response = await createRefer({ token, ...newElement, referCode: e.target.referralCode.value });
      }
      toastr.success(response?.info);
      loadElements();
      document.querySelector('#element-form').reset();
    } catch (error) {
      console.log(error)
      toastr.error(error?.info || error?.response?.data?.info);
    }
  }

  const showAdd = () => {
    setShowAddModal(true);
    setIsEdit(false);
    setElementEdit({});
  }

  const showEdit = (element) => {
    setShowAddModal(true);
    setIsEdit(true);
    setElementEdit(element);
  }

  useEffect(() => {
    loadElements();
  }, [])

  const formatDateMoment = (createdDate) => {
    return moment(createdDate).format('YYYY MMMM DD, h:mm:ss a')
  }

  return (
    <div>
      <div className="section-title-container">
        <h4>
          Referidos
        </h4>
        <button className="btn btn-primary" onClick={showAdd}>
          Agregar
        </button>
      </div>
      <div className="card card-body">
        <div className="table-responsive">
          <table className="table table-hover">
            <thead>
              <tr>
                <th>Referido</th>
                <th>Referente</th>
                <th>Codigo de Referido</th>
                <th>Bono</th>
                <th>Duracion</th>
                <th>Creado en</th>
                <th>Acciones</th>
              </tr>
            </thead>
            <tbody>
              {referrals.map(index => (
                <tr>
                  <td>{getUserName(index.referredUserId)}</td>
                  <td>{getUserName(index.refererId)}</td>
                  <td>{index.referCode}</td>
                  <td>{index.bonus}</td>
                  <td>{index.duration}</td>
                  <td>{formatDateMoment(index.loftyCreatedAt)}</td>
                  <td className="text-danger">
                    <button className="btn btn-primary btn-rounded btn-sm" onClick={() => showEdit(index)}>
                      Editar
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
      <div style={{ display: 'flex', width: '100%', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-end' }}>
        {currentPage > 1 && (
          <button className="btn btn-primary btn-rounded btn-sm" style={{ margin: '5px' }} onClick={() => seekNewData(currentPage - 1)}>
            Previo
          </button>
        )
        }
        <div style={{ margin: '5px' }}>
          Pag: {currentPage} / {counterPage}
        </div>
        {currentPage < counterPage && (
          <button className="btn btn-primary btn-rounded btn-sm" style={{ margin: '5px' }} onClick={() => seekNewData(currentPage + 1)}>
            Siguiente
          </button>
        )}
      </div>
      <div style={{ display: 'flex', width: '100%', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-end' }}>
        <TextField defaultValue={limitData} onChange={(e) => stateNewLimitPage(e.target.value)} select name="rowsPerPage" type="rowsPerPage" label="Datos por Pagina:" variant="standard">
          <MenuItem value={10} key={10}>
            10 por Pagina
          </MenuItem>
          <MenuItem value={20} key={20}>
            20 por Pagina
          </MenuItem>
          <MenuItem value={50} key={50}>
            50 por Pagina
          </MenuItem>
        </TextField>
      </div>
      <AddOrEditModal
        showAddModal={showAddModal}
        setShowAddModal={setShowAddModal}
        handleSubmit={handleSubmit}
        isEdit={isEdit}
        elementEdit={elementEdit}
        userData={users}
      />
    </div>
  )
}

const mapStateToProps = (state) => state.auth;

export default connect(mapStateToProps)(Refers)
