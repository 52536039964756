import axios from '../config/Axios';
export const url = '/api/campaign';

export const campaignTypes = [
  {
    name: 'video',
    label: 'Vídeo',
  },
  {
    name: 'image',
    label: 'Imagen',
  },
  {
    name: 'qr',
    label: 'Código QR',
  }
]

export const createCampaign = ({
  token,
  advertisingId,
  title,
  type,
  media,
  duration,
  latitude,
  longitude,
  urlContact,
  status,
}) => new Promise((resolve, reject) => {
  if (
    advertisingId &&
    title &&
    type &&
    media &&
    duration
  ) {
    const formData = new FormData();
    formData.append('advertisingId', advertisingId);
    formData.append('title', title);
    formData.append('type', type);
    formData.append('media', media);
    formData.append('duration', duration);
    formData.append('latitude', latitude);
    formData.append('longitude', longitude);
    formData.append('url', urlContact);
    formData.append('status', status);
    axios.post(
      `${url}/create/basic/`,
      formData,
      {
        headers: {
          AuthorizationApp: token,
          'Content-Type': 'multipart/form-data',
        },
      }).then((res) => {
        const { data } = res;
        if (data.status === 'success') {
          resolve(data);
        } else {
          reject(data);
        }
      }).catch((error) => reject(error));
  } else if (!title) {
    reject({
      status: 'error',
      info: 'Título definido',
    });
  } else if (!type) {
    reject({
      status: 'error',
      info: 'Tipo no definido',
    });
  } else if (!media) {
    reject({
      status: 'error',
      info: 'Archivo multimedia no definido',
    });
  } else if (!duration) {
    reject({
      status: 'error',
      info: 'Duración no definida',
    });
  } else {
    reject({
      status: 'error',
      info: 'Error al guardar el dato',
    });
  }
});

export const updateCampaign = ({
  token,
  _id,
  advertisingId,
  title,
  type,
  media,
  duration,
  latitude,
  longitude,
  urlContact,
  status,
}) => new Promise((resolve, reject) => {
  if (
    advertisingId &&
    title &&
    type &&
    duration
  ) {
    const formData = new FormData();
    formData.append('advertisingId', advertisingId);
    formData.append('title', title);
    formData.append('type', type);
    formData.append('media', media);
    formData.append('duration', duration);
    formData.append('latitude', latitude);
    formData.append('longitude', longitude);
    formData.append('url', urlContact);
    formData.append('status', status);
    axios.put(
      `${url}/update/basic/${_id}`,
      formData,
      {
        headers: {
          AuthorizationApp: token,
          'Content-Type': 'multipart/form-data',
        },
      }).then((res) => {
        const { data } = res;
        if (data.status === 'success') {
          resolve(data);
        } else {
          reject(data);
        }
      }).catch((error) => reject(error));
  } else if (!title) {
    reject({
      status: 'error',
      info: 'Título definido',
    });
  } else if (!type) {
    reject({
      status: 'error',
      info: 'Tipo no definido',
    });
  } else if (!duration) {
    reject({
      status: 'error',
      info: 'Duración no definida',
    });
  } else {
    reject({
      status: 'error',
      info: 'Error al guardar el dato',
    });
  }
});

export const listCampaign = ({ token, advertisingId, page, count }) => new Promise((resolve, reject) => {
  if (token) {
    axios.get(`${url}/list/basic/${advertisingId}`, {
      headers: {
        AuthorizationApp: token,
      },
      params: {
        page,
        count,
      },
    }).then((res) => {
      const { data } = res;
      if (data.status === 'success') {
        resolve(data);
      } else {
        reject(data);
      }
    }).catch((error) => reject(error));
  } else {
    reject({
      status: 'error',
      info: 'Token no definido',
    });
  }
});

export const totalCompanyCampaign = ({
  token, idCompany, initialDate, endDate,
}) => new Promise((resolve, reject) => {
  if (token && idCompany) {
    axios.post(`${url}/total/company/${idCompany}`, {
      initialDate, endDate,
    }, {
      headers: {
        AuthorizationApp: token
      }
    }).then((res) => {
      if (res.data.status === 'success') {
        resolve(res.data);
      } else {
        reject(res.data);
      }
    }).catch((error) => {
      reject(error?.response?.data);
    });
  } else if (!token) {
    reject({
      status: 'error',
      info: 'Token no definido'
    });
  } else {
    reject({
      status: 'error',
      info: 'Empresa no definido'
    });
  }
});
